import { create } from "zustand";
import { persist } from "zustand/middleware";
import { AuthState, DecodedToken } from "../types/auth.types";
import authService from "../services/auth.service";
import { jwtDecode } from "jwt-decode";
import { UserRole } from "../types/auth.types";
import { toast } from "react-toastify";
import { LoginResponse } from "../types/auth.types";

const handleAuthResponse = (
  response: any,
  set: any,
  name?: string,
  lastName?: string
) => {
  const decodedToken = jwtDecode<DecodedToken>(response.accessToken);

  // Manejar roles desde el token o desde userType
  const roles = Array.isArray(decodedToken.role)
    ? decodedToken.role
    : typeof decodedToken.role === "string"
    ? decodedToken.role.split(",").map((r) => r.trim() as UserRole)
    : [decodedToken.role];

  const nextStepMap: { [key: number]: string } = {
    0: "0",
    1: "emailVerified",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "5",
    7: "6",
    8: "6",
    9: "9",
  };

  console.log("response.nextStep", response.nextStep);
  if (response?.nextStep !== undefined) {
    const nextStepValue = nextStepMap[response.nextStep];
    console.log("nextStepValue", nextStepValue);
    if (nextStepValue !== undefined) {
      localStorage.setItem("nextStep", nextStepValue);
    }
  }
  localStorage.setItem("accessToken", response?.accessToken);
  localStorage.setItem("refreshToken", response?.refreshToken);
  localStorage.setItem("authResponse", JSON.stringify(response));
  localStorage.setItem(
    "routeParameters",
    JSON.stringify(response?.routeParameters)
  );
  localStorage.setItem("role", Array.isArray(decodedToken.role) ? decodedToken.role.join(",") : decodedToken.role);

  console.log("response", response);
  set({
    user: {
      name: name || decodedToken.name,
      lastName: lastName, response },
    role: roles,
    isAuthenticated: true,
    nextStep: response.nextStep,
    isLoading: false,
  });
};

const useAuthStore = create(
  persist<AuthState>(
    (set) => ({
      user: null,
      role: null,
      isAuthenticated: false,
      isLoading: false,
      error: null,
      nextStep: null,

      initAuth: () => {
        const token = localStorage.getItem("accessToken");
        if (token) {
          try {
            const decodedToken = jwtDecode<DecodedToken>(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp && decodedToken.exp < currentTime) {
              set({
                isAuthenticated: false,
                role: null,
                user: null,
              });
            } else {
              set({
                isAuthenticated: true,
                role: decodedToken.role as UserRole,
                user: {
                  ...decodedToken,
                  accessToken: token,
                },
              });
            }
          } catch (error) {
            set({
              isAuthenticated: false,
              role: null,
              user: null,
            });
          }
        }
      },

      login: async (email, password): Promise<LoginResponse> => {
        set({ isLoading: true, error: null });
        try {
          const response = await authService.login(email, password);
          if (!response) {
            throw new Error('Login failed');
          }
          handleAuthResponse(response, set);
          toast.success('Login successful');
          return response;
        } catch (error: any) {
          set({ error: error.message, isLoading: false });
          toast.error(error.message);
          throw error;
        }
      },

      register: async (
        name,
        lastName,
        email,
        password,
        phoneNumber,
        userType
      ) => {
        set({ isLoading: true, error: null });
        try {
          const response = await authService.register({
            name,
            lastName,
            email,
            password,
            phoneNumber,
            userType: (userType as UserRole) || ("USER" as UserRole),
          });
          handleAuthResponse(response, set, name, lastName);
          toast.success('Sign up successful');
          return response;
        } catch (error: any) {
          set({ error: error.message, isLoading: false });
          toast.error(error.message);
          throw error;
        }
      },

      facebookLogin: async (token: string): Promise<LoginResponse> => {
        set({ isLoading: true, error: null });
        try {
          const response = await authService.facebookLogin(token);
          handleAuthResponse(response, set);
          return response;
        } catch (error: any) {
          set({ error: error.message, isLoading: false });
          toast.error(error.message);
          throw error;
        }
      },

      googleLogin: async (token: string): Promise<LoginResponse> => {
        set({ isLoading: true, error: null });
        try {
          const response = await authService.googleLogin(token);
          if (!response) {
            throw new Error('Login failed');
          }
          handleAuthResponse(response, set);
          toast.success('Login successful');
          return response;
        } catch (error: any) {
          set({ error: error.message, isLoading: false });
          toast.error(error.message);
          throw error;
        }
      },

      googleRegister: async (token: string, userType: string) => {
        set({ isLoading: true, error: null });
        try {
          const response = await authService.googleRegister(token, userType);
          handleAuthResponse(response, set);
        } catch (error: any) {
          set({ error: error.message, isLoading: false });
        }
      },

      verifyCode: async (code, emailOrPhone) => {
        set({ isLoading: true, error: null });
        try {
          const response = await authService.verifyCode(code, emailOrPhone);
          handleAuthResponse(response, set);
        } catch (error: any) {
          set({ error: error.message, isLoading: false });
        }
      },

      resendVerificationCode: async () => {
        set({ isLoading: true, error: null });
        try {
          await authService.resendVerificationCode();
          set({ isLoading: false });
        } catch (error: any) {
          set({ error: error.message, isLoading: false });
        }
      },

      resetPassword: async (email) => {
        set({ isLoading: true, error: null });
        try {
          await authService.resetPassword(email);
          set({ isLoading: false });
        } catch (error: any) {
          set({ error: error.message, isLoading: false });
        }
      },

      logout: () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("nextStep");
        localStorage.removeItem("authResponse");
        set({ user: null, isAuthenticated: false });
      },
    }),
    {
      name: "auth-storage",
      partialize: (state) => ({
        ...state,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        role: state.role,
        nextStep: state.nextStep,
      }),
    }
  )
);

export default useAuthStore;
