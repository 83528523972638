import { Navigate } from 'react-router-dom';
import routesConfig from '../config/routes.config';
import { jwtDecode } from 'jwt-decode';

const NextStepGuard = () => {
  const nextStep = localStorage.getItem('nextStep');
  const authResponse = localStorage.getItem('authResponse');
  
  if (!nextStep || !authResponse) {
    return <Navigate to={routesConfig.login} replace />;
  }

  const step = parseInt(nextStep, 10);
  const parsedAuthResponse = JSON.parse(authResponse);
  const accessToken = parsedAuthResponse?.accessToken || '';
  const routeParameters = parsedAuthResponse?.routeParameters || [];

  const parameter = getParameterByStep(step, accessToken, routeParameters);

  const targetRoute = getRouteByNextStep(step, parameter);

  return <Navigate to={targetRoute} replace />;
};
export const getRouteByNextStep = (step: number, parameter?: string) => {
  const userType = localStorage.getItem('role');

  // Si no es PROFESSIONAL, redirigir a landing page
  if (userType === 'USER') {
    return routesConfig.home;
  }

  return routesConfig.onboardingProfessionalPage;
};


const getParameterByStep = (step: number, accessToken: string, routeParameters: string[]) => {
  if (step === 1) {
    return extractEmailFromToken(accessToken);
  } else if (step === 6 && routeParameters.length > 0) {
    return routeParameters[0];
  }
  return '';
};

const extractEmailFromToken = (token: string): string => {
  try {
    const decoded: any = jwtDecode(token);
    return decoded.email || '';
  } catch (error) {
    console.error('Error decoding token:', error);
    return '';
  }
};

export const NextStepGuardRouteString = () => {
  const nextStep = localStorage.getItem('nextStep');
  if (nextStep !== null) {
    localStorage.setItem('nextStep', nextStep);
  }
  const authResponse = localStorage.getItem('authResponse');
  const routeParameter = localStorage.getItem('routeParameters');

  if (!nextStep || !authResponse) {
    return routesConfig.login;
  }

  const step = parseInt(nextStep, 10);
  const parsedAuthResponse = JSON.parse(authResponse);
  const parsedrouteParameter = JSON.parse(routeParameter || '[]');
  const accessToken = parsedAuthResponse?.accessToken || '';
  const routeParameters = parsedAuthResponse?.routeParameters?? parsedrouteParameter ?? [];

  const parameter = getParameterByStep(step, accessToken, routeParameters);

  const targetRoute = getRouteByNextStep(step, parameter?? routeParameters?.length() >0 ?routeParameters[0]:'');

  return targetRoute;
};

export default NextStepGuard;
