import React from 'react';
import FacebookLogin from 'react-facebook-login';
import { useTranslation } from 'react-i18next';
/* import Button from '../../atoms/Button'; */

interface Props {
  onSuccess?: (data: any) => void;
  onFailure?: (error: any) => void;
}

const FacebookAuthButton: React.FC<Props> = ({ onSuccess, onFailure }) => {
  const { t } = useTranslation();

  const responseFacebook = (response: any) => {
    if (response.accessToken) {
      if (onSuccess) {
        onSuccess(response);
      }
    } else {
      if (onFailure) {
        onFailure(response);
      }
    }
  };
  {/* <button
      className="w-full bg-blue-600 text-white p-2 rounded-xl flex items-center justify-center gap-2"
      onClick={() => responseFacebook({ accessToken: 'dummy_access_token' })}
    >
      <i className="fa fa-facebook" />
      {t('facebook')}
    </button> */}
  return (
    
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      onFailure={responseFacebook}
      textButton={t('facebook')}
      cssClass="w-full h-12 flex items-center justify-center gap-3 px-6 bg-blue-600 text-white p-2 rounded-xl"
      icon="fa-facebook"
    />
  );
};

export default FacebookAuthButton;
