import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';

interface Props {
  onClick: () => void;
}

const CompanyButton: React.FC<Props> = ({ onClick }) => {
    const { t } = useTranslation();
    return (
    <Button
      onClick={onClick}
      className="w-full h-12 rounded-xl flex items-center justify-center gap-2"
      size='large'
    >
      <img
        src="/assets/icons/brand/company.png"
        alt="Company Icon"
        className="w-7 h-7"
      />
      <span>{t("createAccountPage.buttonCompany")}</span>
    </Button>
  );
};

export default CompanyButton;