import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/outline";
import useAuthStore from "@/store/useAuthStore";
import { useTranslation } from "react-i18next";
import LoginButton from "@/components/molecules/auth/LoginButton";
import { LanguageToggle } from "@/components/atoms/LanguageToggle";
import ThemeToggle from "@/components/atoms/ThemeToggle";


// Component: Header
const Header: React.FC = () => {
  const { t } = useTranslation();
  const isLoggedIn = useAuthStore((state) => state.isAuthenticated);
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  // Toggle menu visibility
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Toggle language menu visibility
  const toggleLanguageMenu = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  // Get link class based on the current path
  const getLinkClass = (path: string) => {
    return location.pathname === path
      ? "text-textBrand dark:text-dark-textBrand"
      : "text-cText dark:text-dark-cText";
  };

  // NavLink component
  const NavLink = ({ href, children, disabled }: { href: string; children: React.ReactNode; disabled?: boolean }) => (
    <a
      href={ disabled ? undefined : href }
      className={`${getLinkClass(href)} ${disabled ? "text-cTextSecondary dark:text-dark-cTextSecondary pointer-events-none" : ""} ${isLoggedIn || href === "/" || href === "/marketplace" ? "" : "text-outline pointer-events-none"}`}
    >
      {children}
    </a>
  );

  // Routes for navigation
  const routes = [
    { path: "/", label: t("navbar.home") },
    { path: "/services", label: t("navbar.myServices"), requiresAuth: true  },
    { path: "/requests", label: t("navbar.myRequests"), requiresAuth: true },
    { path: "/marketplace", label: t("navbar.marketplace") },
    { path: "/mi-perfil", label: t("navbar.myProfile"), requiresAuth: true },
  ];

  return (
    <nav className="flex py-4 md:py-6 md:px-4 3xl:container 3xl:mx-auto">
      <div className="flex flex-wrap items-center justify-between w-full">
        {/* Logo */}
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <span className="pl-4 text-textBrand text-center font-serif dark:text-dark-bgBrand text-2xl font-semibold leading-10 tracking-[5.50px] self-center whitespace-nowrap">
            XERVIX
          </span>
        </a>

        {/* Navigation links */}
        <ul className="hidden text-cText dark:text-dark-cText font-medium xl:flex xl:gap-10">
          {routes.map((route) => (
            <li key={route.path}><NavLink href={route.path} disabled={route.requiresAuth && !isLoggedIn}>{route.label}</NavLink></li>
          ))}
        </ul>

        {/* Theme and language toggles */}
        <div className="flex items-center pr-2 relative">

          {/* Theme toggle button */}
          <ThemeToggle />

          {/* Language toggle button */}
          <LanguageToggle />

          {/* Login section */}
          <div className="hidden xl:flex">
            <LoginButton />
          </div>

          {/* Hamburger menu button */}
          <button
            onClick={toggleMenu}
            className="inline-flex items-center justify-center xl:hidden size-10 text-sm text-cText rounded-lg hover:bg-cBackgroundSecondary focus:bg-cBackgroundSecondary dark:text-dark-cText dark:hover:bg-dark-cBackgroundSecondary dark:focus:bg-dark-cBackgroundSecondary"
            aria-expanded={menuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="size-6 text-cText dark:text-dark-cText" />
          </button>
        </div>

        {/* Mobile menu */}
        <div
          className={`${menuOpen ? "block" : "hidden"} w-full mt-4 md:mt-0 rounded-lg bg-cBackground dark:bg-dark-cBackground`}
          id="navbar-hamburger"
        >
          <ul className="flex flex-col font-medium space-y-2">
            {routes.map((route) => (
              <li key={route.path}>
                <a
                  href={route.path}
                  className="block py-2 px-4 text-cText dark:text-dark-cText rounded hover:bg-bgBrand hover:text-white"
                >
                  {route.label}
                </a>
              </li>
            ))}
            <li>
              <a
                href="#"
                className="block px-4 py-2 text-cText dark:text-dark-cText rounded hover:bg-bgBrand hover:text-dark-cText"
              >
                <LoginButton />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
