import { xervixApi } from '../config/axios.config';
import { API_ROUTES } from '../constants/apiRoutes';
import { CategoryQuerySearchResponse, CategoriesResponse, CategoryHierarchy } from '../types/category.types';

const fetchCategories = async (): Promise<CategoriesResponse> => {
  const response = await xervixApi.get(API_ROUTES.CATEGORIES.FETCH_CATEGORIES, {
    params: {
      page: 1,
      pageSize: 1000,
    },
  });
  return response.data as CategoriesResponse;
};

const fetchCategory = async (categoryId: string): Promise<CategoryHierarchy> => {
  const response = await xervixApi.get(API_ROUTES.CATEGORIES.FETCH_CATEGORY(categoryId));
  return response.data as CategoryHierarchy;
}

const searchCategories = async (query: string): Promise<CategoryQuerySearchResponse[]> => {
  const response = await xervixApi.get(API_ROUTES.CATEGORIES.SEARCH_CATEGORIES, {
    params: { query },
  });
  return response.data as CategoryQuerySearchResponse[];
};

const fetchCategoriesHierarchy = async (): Promise<CategoryHierarchy[]> => {
  const response = await xervixApi.get(API_ROUTES.CATEGORIES.FETCH_CATEGORIES_HIERARCHY);
  return response.data as CategoryHierarchy[];
};


export default {
  fetchCategories,
  fetchCategory,
  searchCategories,
  fetchCategoriesHierarchy, // Ensure this is exported
};
