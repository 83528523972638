import { useTranslation } from "react-i18next";
import Button from '../../atoms/Button';

interface JoinCommunityProps {
  onJoinClick: () => void;
}

const JoinCommunity: React.FC<JoinCommunityProps> = ({ onJoinClick }) => {
  const { t } = useTranslation();
  return (
    <section className="flex flex-col md:flex-row items-center justify-between py-2 lg:py-16 px-2 md:px-1 lg:px-28 text-center md:text-left lg:text-left">
      <div className="md:w-1/2 mb-8 md:mb-0">
        <h2 className="text-2xl lg:text-3xl font-bold mb-4 py-6">
          {t("landingPage.joinCommunityHero.title")}
        </h2>
        <p className="text-cTextSecondary dark:text-dark.cTextSecondary mb-6">
          {t("landingPage.joinCommunityHero.subtitle")}
        </p>
        <Button
          onClick={onJoinClick}
        >
          {t("landingPage.joinCommunityHero.joinButtonText")}
        </Button>
      </div>
      <div className="relative w-full md:w-1/2">
        <img
          src="/assets/img/landing-page/red-woman.png"
          alt="Professional"
          className="w-140 mx-auto"
        />
        {/* Floating avatars */}
        <div className="hidden lg:block absolute top-4 right-4">
          <img
            src="/assets/img/landing-page/Ellipse-1.png"
            alt="Member 1"
            className="rounded-full"
          />
        </div>
        <div className="hidden lg:block absolute bottom-1/2 left-10">
          <img
            src="/assets/img/landing-page/Ellipse-2.png"
            alt="Member 2"
            className="rounded-full"
          />
        </div>
        <div className="hidden lg:block absolute top-60 left-20">
          <img
            src="/assets/img/landing-page/Ellipse-3.png"
            alt="Member 3"
            className="rounded-full"
          />
        </div>
        <div className="hidden lg:block absolute bottom-4 -right-10">
          <img
            src="/assets/img/landing-page/Ellipse-4.png"
            alt="Member 3"
            className="rounded-full"
          />
        </div>
      </div>
    </section>
  );
};

export default JoinCommunity;
