
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import useAuthStore from "../../../store/useAuthStore";
import Button from "../../atoms/Button";

const LoginButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuthStore();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    setIsDropdownOpen(false);
  };

  // Click away listener
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <>
      {isAuthenticated ? (
        <div className="flex flex-row gap-2 items-center relative" ref={dropdownRef}>
          <p className="font-medium">{user.name}</p>
          <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="cursor-pointer">
            <UserCircleIcon className="size-8 stroke-textBrand fill-bgBrandLight dark:fill-transparent dark:stroke-bgBrandLight" />
          </div>
          
          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute right-0 top-full mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
              <div className="py-1">
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  {t('logout')}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Button
          onClick={handleLoginClick}
          variant="outlined"
          className="flex flex-row gap-2 items-center hover:text-cText dark:text-dark-cText"
        >
          <p className="font-medium">{t("login")}</p>
          <UserCircleIcon className="size-8 stroke-textBrand fill-bgBrandLight dark:fill-transparent dark:stroke-bgBrandLight" />
        </Button>
      )}
    </>
  );
};

export default LoginButton;