import React from 'react';
import { useModalStore } from '../../store/useModalStore';
import {  InformationModal, ConfirmationModal, ServiceCompletionModal} from './ModalsAction/Modals';

export const Modal = () => {
  const { isOpen, modalType, config, closeModal } = useModalStore();

  if (!isOpen || !modalType) return null;

  const handleConfirm = () => {
    if (config.onConfirm) {
      config.onConfirm();
    }
    closeModal();
  };

  const handleCancel = () => {
    if (config.onCancel) {
      config.onCancel();
    }
    closeModal();
  };

  const modalComponents = {
    'confirmation': (
      <ConfirmationModal
        title={config.title || '¿Estás seguro?'}
        message={config.message || '¿Estás seguro que quieres realizar esta acción?'}
        confirmButtonText={config.confirmButtonText || 'Aceptar'}
        cancelButtonText={config.cancelButtonText || 'Cancelar'}
        confirmButtonColor={config.confirmButtonColor || 'bg-red-500 hover:bg-red-600'}
        cancelButtonColor={config.cancelButtonColor || 'bg-gray-200 hover:bg-gray-300'}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    ),
    'service-completion': (
      <ServiceCompletionModal
        title={config.title || 'Servicio Finalizado'}
        message={config.message || 'Por favor confirma el estado del servicio'}
        completeButtonText={config.confirmButtonText || 'El servicio fue realizado'}
        incompleteButtonText={config.cancelButtonText || 'El servicio no fue realizado'}
        completeButtonColor={config.confirmButtonColor || 'bg-green-500 hover:bg-green-600'}
        incompleteButtonColor={config.cancelButtonColor || 'bg-gray-200 hover:bg-gray-300'}
        companyName={config.additionalData?.companyName || 'La empresa'}
        onComplete={handleConfirm}
        onIncomplete={handleCancel}
      />
    ),
    'information': (
      <InformationModal
        title={config.title || 'No se puede cancelar'}
        message={config.message || 'El servicio no puede ser cancelado debido a que faltan menos de 24 horas'}
        acceptButtonText={config.confirmButtonText || 'Aceptar'}
        acceptButtonColor={config.confirmButtonColor || 'bg-red-500 hover:bg-red-600'}
        onAccept={handleConfirm}
        terms={config.terms || false}
      />
    )
  };

  return modalComponents[modalType];
};

export default Modal;