import React from 'react';
import ActiveServices from '../components/organisms/services/ActiveServices';

const MyServicesPage: React.FC = () => {
    return (
        <div>
            <h1>Our Services</h1>
            <ActiveServices />
            <p>Welcome to the Services Page. Here we offer a variety of services to meet your needs.</p>
        </div>
    );
};

export default MyServicesPage;