// src/components/ServicioCard.tsx
import React from "react";

interface ServicioCardProps {
  servicio: {
    id: number;
    categoria: string;
    descripcion: string;
    fecha: string;
    proveedor: string;
    imagen: string;
  };
  onClick: () => void;
}

const ServiceCard: React.FC<ServicioCardProps> = ({ servicio, onClick }) => {
  return (
    <div
      className="flex flex-col bg-white p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition"
      onClick={onClick}
    >
      <img src={servicio.imagen} alt={servicio.categoria} className="rounded-t-lg h-32 w-full object-cover" />
      <div className="mt-4">
        <h4 className="font-bold text-lg">{servicio.categoria}</h4>
        <p className="text-gray-700 text-sm">{servicio.descripcion}</p>
        <p className="text-gray-500 text-xs mt-2">{servicio.fecha}</p>
        <p className="text-gray-500 text-xs">{servicio.proveedor}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
