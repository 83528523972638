import React from 'react';
import { Outlet } from 'react-router-dom';
import { useThemeStore } from '../../store/useThemeStore';

export const AuthLayout = () => {
  const { isDarkMode } = useThemeStore();
  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <div className="bg-white dark:bg-dark-cBackground text-cText dark:text-white">
       <Outlet />
      </div>
    </div>
  );
};