import * as Yup from "yup";

const PASSWORD_MIN_LENGTH = 6;
const PASSWORD_NUMBER_REGEX = /(?=.*\d)/;
const PASSWORD_SYMBOL_REGEX = /(?=.*\W)/;

export const getSignUpFormSchema = (t: (key: string) => string, userType: string) => {
  const baseSchema = {
    email: Yup.string()
      .required(t("signupPage.requiredEmail"))
      .email(t("signupPage.invalidEmail")),
    password: Yup.string()
      .required(t("signupPage.requiredPassword"))
      .min(PASSWORD_MIN_LENGTH, t("signupPage.minLength"))
      .matches(PASSWORD_NUMBER_REGEX, t("signupPage.hasNumber"))
      .matches(PASSWORD_SYMBOL_REGEX, t("signupPage.hasSymbol")),
      confirmPassword: Yup.string()
      .required(t("signupPage.requiredConfirmPassword"))
      .oneOf([Yup.ref("password")], t("signupPage.passwordsMatch"))
  }

  if (!userType?.includes('PROFESSIONAL')) {
    return Yup.object().shape({
      ...baseSchema,
      firstName: Yup.string().required(t("signupPage.requiredFirstName")),
      lastName: Yup.string().required(t("signupPage.requiredLastName")),
      phoneNumber: Yup.string()
        .required(t("signupPage.requiredPhoneNumber"))
        .matches(/^[0-9]+$/, t("signupPage.invalidPhoneNumber")),
    });
  }


  return Yup.object().shape(baseSchema);
};
