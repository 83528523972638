import React, { memo } from "react";
import { CategoryHierarchy } from "../../types/category.types";
import { Card } from "../atoms/Card";

interface CategoryCardProps {
  category: CategoryHierarchy;
  isSelected?: boolean;
  totalServices?: number;
  isNavigable?: boolean;
  loading?: boolean;
  handleClick: (category: CategoryHierarchy) => void;
}

const CategorySubServices: React.FC<{
  subCategories: CategoryHierarchy["subCategories"];
}> = memo(({ subCategories }) => (
  <ul className="mt-2">
    {subCategories
      .flat()
      .slice(0, 3)
      .map((service, index) => (
        <li
          key={index}
          className="text-cTextSecondary dark:text-dark-cTextSecondary"
        >
          {service.name}
        </li>
      ))}
  </ul>
));

export const CategoryCard: React.FC<CategoryCardProps> = ({
  category,
  isSelected,
  totalServices,
  isNavigable = true,
  handleClick,
}) => {

  const textClasses = isSelected ? "text-textOnBrand" : "";

  return (
    <div
      key={category.categoryId}
      className={`relative flex-none w-64 cursor-pointer select-none`}
      onClick={() => handleClick(category)}
    >
      <Card
        variant={isSelected ? "selected" : "default"}
        hoverable
        className="p-4 xl:p-2 bg-bgBrand"
      >
        <img
          src={category.urlImage}
          alt={category.name}
          className="w-full h-60 xl:h-64 object-cover rounded-3xl p-2"
        />
        <div className="p-4">
          <div className="flex flex-col xl:flex-row items-start xl:items-center justify-between gap-2">
            <h3 className={`font-semibold text-xl text-cText dark:text-dark-cText ${textClasses}`}>
              {category.name}
            </h3>
            <p className={`text-sm text-cTextSecondary dark:text-dark-cTextSecondary ${textClasses} whitespace-nowrap`}>
              {totalServices} Servicios
            </p>
          </div>
          {isNavigable && category.subCategories && (
            <CategorySubServices subCategories={category.subCategories} />
          )}
        </div>
      </Card>
    </div>
  );
};
