import DynamicForm from "../components/organisms/DynamicForm";
import { BaseLayout } from "../components/templates/BaseLayout";

const ServiceFormPage = () => {
  return (
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">
          Ingresa la siguiente información
        </h1>
          <DynamicForm />
      </div>
  );
};

export default ServiceFormPage;
