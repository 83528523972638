import {
  Bars3Icon,
  XMarkIcon,
  Squares2X2Icon,
  UserIcon,
  ChatBubbleLeftIcon,
  BriefcaseIcon,
  DocumentTextIcon,
  ShoppingCartIcon,
  HeartIcon,
  Cog6ToothIcon,
  SunIcon,
  MoonIcon,
  ArrowLeftStartOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { NavItem } from "../molecules/NavItem";
import { useSidebarStore } from "../../store/useSidebarStore";
import routesConfig from "../../config/routes.config";


const navItems = [
  { href: routesConfig.dashboardProfessional, icon: <Squares2X2Icon />, label: "Dashboard" },
  { href: routesConfig.userProfessional, icon: <UserIcon />, label: "Users" },
  { href: routesConfig.chatProfessional, icon: <ChatBubbleLeftIcon />, label: "Messages" },
  { href: routesConfig.servicesProfessional, icon: <BriefcaseIcon />, label: "Services" },
  { href: routesConfig.proposalsProfessional, icon: <DocumentTextIcon />, label: "Propuestas" },
  { href: routesConfig.marketplaceProfessional, icon: <ShoppingCartIcon />, label: "Marketplace" },
  { href: routesConfig.paymentsProfessional, icon: <HeartIcon />, label: "Historial de pagos" },
  { href: routesConfig.configProfessional, icon: <Cog6ToothIcon />, label: "Configuración" },
];

export const SidebarDashboard = () => {
  const { isExpanded, toggleSidebar } = useSidebarStore();
  const handleSignOut = () => {
    localStorage.clear();
    // Add your sign out logic here (e.g., redirect to login)
  };

  return (
    <aside
      className={`
        sticky left-0 top-0 h-screen bg-white border-r border-gray-200 p-4
        transition-all duration-300
        ${isExpanded ? "w-64" : "w-20"}
      `}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-row">
          {isExpanded && (
            <>
              {/* Logo */}
              <div className="mb-8">
                <img src="/logo512.png" alt="Logo" className="h-12" />
              </div>
            </>
          )}

          {/* Toggle Button */}
          <button
            onClick={toggleSidebar}
            className="mb-8 p-2 hover:bg-gray-100 rounded-lg self-end"
          >
            <Bars3Icon className="w-6 h-6" />
          </button>
        </div>

        {/* Navigation */}
        <nav className="flex-1 space-y-1">
          {navItems.map((item, index) => (
            <NavItem
              key={index}
              {...item}
              active={window.location.pathname === item.href}
            />
          ))}
        </nav>

        {/* Bottom Section */}
        <div className="border-t border-gray-200 pt-4">
          <NavItem
            icon={<SunIcon />}
            label="Light Mode"
            onClick={() => {
              /* Add theme toggle logic */
            }}
          />

          {/* Profile */}
          {isExpanded ? (
            <div
              className={`
            flex items-center gap-3 px-4 py-3 mt-2
            ${isExpanded ? "" : "justify-center"}
          `}
            >
              <img
                src="/avatar.jpg"
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div className="flex-1">
                <p className="font-medium text-gray-900">John Doe</p>
              </div>
              <button
                onClick={handleSignOut}
                className="text-sm text-red-600 hover:text-red-700 flex items-center gap-2"
              >
                <ArrowLeftStartOnRectangleIcon className="size-6" />
              </button>
            </div>
          ) : (
            <NavItem
              onClick={handleSignOut}
              icon={<ArrowLeftStartOnRectangleIcon />}
              className="text-red-600 hover:text-red-700"
            />
          )}
        </div>
      </div>
    </aside>
  );
};
