import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button';

const MarketplacePreview: React.FC<{ onButtonClick: () => void }> = ({ onButtonClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="relative w-full max-w-6xl mx-auto px-4 py-16">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[120%] h-[400px] rounded-[100%] -z-10" />
      <div className="flex flex-col items-center">
        <div className="relative w-full max-w-3xl mx-auto mb-12">
          <div className="absolute left-0 top-1/2 -translate-y-1/2 w-[300px] transform -rotate-12 z-0">
            <div className="relative w-full pb-[200%] bg-black rounded-[40px] overflow-hidden border-8 border-black">
              <img
                src='/assets/img/landing-page/reel-1.png'
                alt="Reel left"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="relative mx-auto w-[300px] z-10">
            <div className="relative w-full pb-[200%] bg-black rounded-[40px] overflow-hidden border-8 border-black">
              <img
                src='/assets/img/landing-page/reel-2.png'
                alt="Reel center"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="absolute right-0 top-1/2 -translate-y-1/2 w-[300px] transform rotate-12 z-0">
            <div className="relative w-full pb-[200%] bg-black rounded-[40px] overflow-hidden border-8 border-black">
              <img
                src='/assets/img/landing-page/reel-3.png'
                alt="Reel right"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
        <div className="text-center max-w-3xl mx-auto">
          <h2 className="text-3xl font-bold text-cText dark:text-dark-cText mb-2">
            {t("landingPage.marketplaceHero.title")}
          </h2>
          <Button
            onClick={onButtonClick}
            uppercase
          >
            {t("landingPage.marketplaceHero.marketplaceButtonText")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MarketplacePreview;