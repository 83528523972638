import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import collage from '../../../assets/images/services/collage.png';
import Button from '../../atoms/Button';

const ServicesHero: React.FC<{ onButtonClick: () => void }> = ({ onButtonClick }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const renderCollage = (additionalClasses: string) => (
        <div className={`w-full lg:p-4 xl:p-8 ${additionalClasses}`}>
            <div className="animate-scroll">
                <img src="/assets/img/landing-page/collage.png" alt="banner collage" className="w-full h-28 object-cover" />
            </div>
        </div>
    );

    return (
        <div className="flex flex-col gap-4 p-4 items-center w-full overflow-hidden bg-cBackground dark:bg-dark-cBackground border border-cBorder dark:border-dark-cBorder lg:w-fit lg:mx-2 lg:rounded-2xl xl:relative">
            {renderCollage("opacity-60")}
            <div className="flex flex-col items-center text-center gap-4 xl:relative xl:z-10 xl:max-w-3xl xl:mx-auto xl:px-2 xl:my-5">
                <h1 className="text-2xl font-semibold text-cText dark:text-dark-cText lg:text-3xl xl:font-bond">
                    {t('landingPage.servicesHero.title')}
                </h1>
                <p className="text-xl text-cTextSecondary dark:text-dark-cTextSecondary">
                    {t('landingPage.servicesHero.subtitle')}
                </p>
                <Button
                    onClick={onButtonClick}
                    uppercase
                >
                    {t('landingPage.servicesHero.buttonText')}
                </Button>
            </div>
            {renderCollage("xl:mt-2 opacity-60")}
        </div>
    );
};

export default ServicesHero;