export const API_ROUTES = {
    ACCOUNT: {
        LOGIN: '/Account/login',
        REGISTER: '/Account/register',
        RESET_PASSWORD: '/Account/reset-password',
        VERIFY_CODE: '/Account/verify-code',
        RESEND_VERIFICATION_CODE_EMAIL: '/Account/resend-verification-code-email',
        FACEBOOK_LOGIN: '/Account/facebook-login',
        GOOGLE_LOGIN: '/Account/google-login',
        GOOGLE_REGISTER: '/Account/google-sign-up',
        REFRESH_TOKEN: '/Account/refresh-token',
      },
      CATEGORIES: {
        FETCH_CATEGORIES: '/Categories',
        FETCH_CATEGORY: (categoryId: string) => `/Categories/${categoryId}`,
        SEARCH_CATEGORIES: '/Categories/search',
        FETCH_CATEGORIES_HIERARCHY: '/Categories/hierarchy-summarys?IsActive=true',
      },
      PROFESSIONAL: {
        UPSERT_BUSINESS_INFO: '/Professionals/upsert-business-info',
        UPDATE_REFERAL_EMAIL: '/Professionals/update-referal-email',
        ACCEPT_TERMS_AND_CONDITIONS: '/Professionals/accept-terms-and-conditions',
        COMPLETE_ONBOARDING: '/Professionals/complete-onboarding',
        SUBMIT_CATEGORIES: '/Professionals/bulk-categories',
        SUBMIT_SERVICES: '/Professionals/bulk-services',
        UPDATE_LOCATION: '/Professionals/update-location',
        UPLOAD_DOCUMENTS: '/ProfessionalDocuments/upload-documents',
        FETCH_AVAILABLE_SERVICES: '/Professionals/available-services',
      },
      REQUEST: {
        FETCH_REQUESTS: '/service-request/get-all-by-user',
        SUBMIT_REQUEST: '/service-request',
        MODE: '/service-request',
        UPLOAD_IMAGES: (serviceRequestId: string) => `/service-request/${serviceRequestId}/upload-images`,

      },
      SERVICES: {
        FETCH_SERVICES: '/Services',
        FETCH_SERVICE: (serviceId: string) => `/Services/${serviceId}`,
        BY_CATEGORY_SUBCATEGORY: `/Services/by-categoryId-subCategoryId`,
        FETCH_TOP_SERVICES: '/service-request/get-services-request-popular',
      },
      ENUMS: {
        SERVICE_MODES: '/enums/service-modes',
      },
      BUSINESS_TYPES: '/BusinessTypes',
      PROFILE: (userType: string) => `/profile/user-type/${userType}`
};