import React from "react";
import LoginForm from "@/components/organisms/LoginForm";

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-8 relative">
        {/* Botón de cierre */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>

        {/* Encabezado */}
        <h1 className="text-2xl font-bold text-center mb-4">
          ¡Hola! Bienvenido de nuevo!
        </h1>
        <p className="text-sm text-center text-gray-600 mb-6">
          Para enviar tu solicitud, necesitamos que te registres o ingreses a tu cuenta. <br />
          <span className="font-medium text-gray-800">
            ¡Prometemos que será rápido y sin complicaciones!
          </span>
        </p>

        <div className="flex flex-col items-center justify-center w-full p-8">
            {/* Formulario de inicio de sesión */}
            <LoginForm
            onClose={onClose}
            onSuccess={() => {
                console.log("Login exitoso.");
                onClose();
            }}
            />
        </div>

        {/* Enlace para crear cuenta */}
        <p className="mt-6 text-sm text-center text-gray-500">
          ¿Aún no tienes una cuenta?{" "}
          <a href="/register" className="text-blue-500 hover:underline">
            Crear una cuenta
          </a>
        </p>

        {/* Separador y botones de inicio con redes sociales */}
        <div className="my-4 flex items-center justify-center">
          <span className="text-sm text-gray-500">O inicia sesión con</span>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
