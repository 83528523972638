import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NextStepGuardRouteString } from '../../guards/NextStepGuard';
import FacebookAuthButton from '../../components/molecules/auth/FacebookAuthButton';
import { GoogleAuthButton } from '../../components/molecules/auth/GoogleAuthButton';
import EmailAuthButton from '../../components/molecules/auth/EmailAuthButton';
import CompanyButton from '../../components/molecules/auth/CompanyButton';
import useAuthStore from '../../store/useAuthStore';
import routesConfig from '../../config/routes.config';
import { ThemeToggle } from '../../components/atoms/ThemeToggle';
import { LanguageToggle } from '../../components/atoms/LanguageToggle';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';


type RegistrationSection = {
  title: string;
  description?: string;
  subText?: string;
  showRegistrationOptions: boolean;
  actionButton?: React.ReactNode;
};

const CreateAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { googleRegister } = useAuthStore();
  
  const isProfessionalRegistration = location.pathname.includes('company');
  const userType = isProfessionalRegistration ? 'PROFESSIONAL' : 'USER';

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      await googleRegister(credentialResponse.credential, userType);
      localStorage.setItem("nextStep", "1");
      const redirectTo = userType === 'PROFESSIONAL' ? NextStepGuardRouteString() : '/login';
      navigate(redirectTo);
    } catch (error) {
      console.error('Error during Google signup:', error);
    }
  };

  const handleEmailSignUp = () => {
    navigate(userType === 'PROFESSIONAL' ? routesConfig.createAccountCompanyForm : routesConfig.createAccountForm);
  }

  const handleCompanySignUp = () => {
    navigate(routesConfig.createAccountCompany);
  };

  const renderRegistrationOptions = () => (
    <div className="flex flex-col gap-4 w-full">
      <EmailAuthButton onClick={handleEmailSignUp} />
      <GoogleAuthButton onSuccess={handleGoogleSuccess} />
      <FacebookAuthButton />
    </div>
  );

  const sections: RegistrationSection[] = isProfessionalRegistration ?
    [
      {
        title: t('createAccountPage.titleCompany'),
        description: t('createAccountPage.descriptionCompany'),
        subText: t('createAccountPage.subTextCompany'),
        showRegistrationOptions: true
      }
    ] : [
      {
        title: t('createAccountPage.titleUser'),
        showRegistrationOptions: true
      },
      {
        title: t('createAccountPage.titleCompany'),
        description: t('createAccountPage.descriptionCompany'),
        subText: t('createAccountPage.subTextCompany'),
        showRegistrationOptions: false,
        actionButton: <CompanyButton onClick={handleCompanySignUp} />
      }
    ];

  return (
    <div className="flex min-h-screen dark:bg-dark-cBackground">
      <div className="absolute top-4 right-4 flex items-center gap-2 z-10">
        <ThemeToggle />
        <LanguageToggle />
      </div>
      <div
        className="hidden md:flex md:w-2/3 bg-cover bg-center relative"
        style={{ backgroundImage: 'url(/assets/img/backgrounds/signup-side-img.jpg)' }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-start top-16">
          <h1 className="text-6xl font-bold mb-4 text-bgBrand">XERVIX</h1>
          <p className="text-2xl font-light text-dark-cText">{t('createAccountPage.services')}</p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center md:w-1/3 w-full gap-10">
        <div className="flex items-center justify-center gap-2">
          <ArrowLeftIcon className="size-4 text-cTextSecondary" />
          <Link
            to={routesConfig.login}
            className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary hover:text-textBrand transition-colors"
          >
            {t('createAccountPage.backToLogin')}
          </Link>
        </div>
        {sections.map((section, index) => (
          <div key={index} className="w-full max-w-sm flex flex-col gap-8">
            <h1 className="text-2xl font-bold text-cText dark:text-dark-cText text-center">{section.title}</h1>
            {section.description && (
              <p className="text-cTextSecondary dark:text-dark-cTextSecondary text-left">{section.description}</p>
            )}
            {section.subText && (
              <span className='text-textBrand dark:text-dark-textBrand text-sm font-normal text-left'>
                {section.subText}
              </span>
            )}
            {section.showRegistrationOptions && renderRegistrationOptions()}
            {section.actionButton}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreateAccountPage;
