import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Stepper from '../components/molecules/Stepper';
import CategoryOnboardingPage from './onboarding/CategoryOnboardingPage';
import ServiceOnboardingPage from './onboarding/ServiceOnboardingPage';
import LocalizationOnboardingPage from './onboarding/LocalizationOnboardingPage';
import BusinessOnboardingPage from './onboarding/BusinessOnboardingPage';
import SuccessOnboardingPage from './onboarding/SuccessOnboardingPage';
/* import TermsAndConditionsPage from './TermsAndConditionsPage'; */
import { NextStepGuardRouteString } from '../guards/NextStepGuard';
import SubscriptionTermsOnboardingPage from './onboarding/SubscriptionTermsOnboardingPage';



const OnboardingProfessionalPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const nextStep = localStorage.getItem('nextStep');
    if (nextStep) {
      setCurrentStep(parseInt(nextStep, 10));
    }
  }, []);

  const handleNextStep = (nextStep: number, categoryId?: string) => {
    if (categoryId) {
      setSelectedCategory(categoryId);
    }
    localStorage.setItem('nextStep', nextStep.toString());
    setCurrentStep(nextStep);
    const targetRoute = NextStepGuardRouteString();
    navigate(targetRoute);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CategoryOnboardingPage onNext={(categoryId: string) => handleNextStep(2, categoryId)} />;
      case 2:
        return <ServiceOnboardingPage onNext={() => handleNextStep(3)}/>;
      case 3:
        return <LocalizationOnboardingPage onNext={() => handleNextStep(4)} />;
      case 4:
        return <BusinessOnboardingPage onNext={() => handleNextStep(5)} />;
      case 5:
        return <SubscriptionTermsOnboardingPage onNext={() => handleNextStep(6)} />;
      case 6:
        return <SuccessOnboardingPage />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen mt-8 bg-white 3xl:container 3xl:mx-auto">
      <Stepper currentStep={currentStep} />
      {renderStepContent()}
    </div>
  );
};

export default OnboardingProfessionalPage;