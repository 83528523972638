import { useNavigate } from "react-router-dom";
import { SidebarDashboard } from "../components/organisms/sidebarDashboard";
import { useState } from "react";
import routesConfig from "../config/routes.config";


interface HeaderProps {
  toggleSidebar: () => void;
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const Header: React.FC<HeaderProps> = ({
  toggleSidebar,
  isDarkMode,
  toggleDarkMode,
}) => {
  return (
    <header className="pl-4 pr-8 py-2.5 flex justify-between bg-gray-100 items-center">
      <div className="flex items-center bg-green-500 text-white px-4 py-2 rounded-md">
        CUENTA ACTIVA
      </div>
      <div className="space-x-2">
        <button className="px-4 py-2">Navegar como Usuario</button>
        <button className="px-4 py-2 text-red-500">Navegar como Empresa</button>
      </div>
      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-2">
          <span>Maria Fernanda Uribe</span>
          <img
            src="/placeholder.svg?height=32&width=32"
            alt="Profile"
            className="w-8 h-8 rounded-full"
          />
        </div>
        {/* <button onClick={toggleDarkMode}>
            {isDarkMode ? <SunIcon /> : <MoonIcon />}
          </button> */}
      </div>
    </header>
  );
};

const ProfileColumn: React.FC = () => {
    return (
      <div className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md">
        <img
          src="https://via.placeholder.com/150"
          alt="Profile"
          className="w-24 h-24 rounded-full"
        />
        <h2 className="mt-4 text-xl font-bold">Catalina Patiño Casas</h2>
        <p className="text-gray-500">UI UX DESIGN</p>
        <button className="mt-4 px-4 py-2 text-sm text-white bg-blue-500 rounded-lg">
          Editar
        </button>
        <div className="mt-6">
          <h3 className="text-left font-semibold mb-2">Historia de UI UX DESIGN</h3>
          <p className="text-gray-700 text-sm">
            Un día cuenta de que quería hacer algo más que simplemente escuchar música; quería compartir su amor por ella con el mundo...
          </p>
          <button className="mt-2 text-sm text-blue-500">Editar</button>
        </div>
      </div>
    );
  };


  const InfoColumn: React.FC = () => {
    return (
      <div className="flex flex-col p-6 bg-white rounded-lg shadow-md">
        <h3 className="font-bold text-lg mb-4">Información de Contacto</h3>
        <div className="space-y-4">
          <div>
            <label className="text-sm font-semibold text-gray-700">Nombre Legal</label>
            <p className="text-gray-800">Juan Sebastian Martinez</p>
            <button className="text-sm text-blue-500">Editar</button>
          </div>
          <div>
            <label className="text-sm font-semibold text-gray-700">Correo Electrónico</label>
            <p className="text-gray-800">j.martinez@gmail.com</p>
          </div>
          <div>
            <label className="text-sm font-semibold text-gray-700">Teléfono</label>
            <p className="text-gray-800">+57 3056355254</p>
          </div>
          <div>
            <label className="text-sm font-semibold text-gray-700">Dirección</label>
            <p className="text-gray-800">Carrera 50 # 54-85</p>
            <button className="text-sm text-blue-500">Editar</button>
          </div>
        </div>
      </div>
    );
  };

  const DocumentationColumn: React.FC = () => {
    return (
      <div className="flex flex-col p-6 bg-white rounded-lg shadow-md">
        <h3 className="font-bold text-lg mb-4">Documentación UI UX DESIGN</h3>
        <p className="text-gray-700 text-sm mb-4">
          Recuerda que no puedes comenzar a vender tus servicios dentro de Xervix hasta que envíes todos los documentos.
        </p>
        <div className="space-y-3">
          {[
            { name: "Cédula Representante.pdf", size: "4.5MB", type: "PDF" },
            { name: "Camara de comercio.xls", size: "12.4MB", type: "XLS" },
            { name: "Certificado bancario.xls", size: "12.4MB", type: "XLS" },
            { name: "RUT.xls", size: "12.4MB", type: "XLS" },
          ].map((doc, index) => (
            <div
              key={index}
              className="flex justify-between items-center border-b pb-2"
            >
              <p className="text-gray-700 text-sm">{doc.name}</p>
              <span className="text-gray-500 text-xs">{doc.size}</span>
              <button className="text-blue-500 text-sm">Descargar</button>
            </div>
          ))}
        </div>
        <div className="mt-6">
          <h4 className="font-semibold">Imágenes UI UX DESIGN</h4>
          <p className="text-gray-700 text-sm">
            Aquí podrás subir las imágenes que serán mostradas en tu perfil...
          </p>
          <button className="mt-4 px-4 py-2 bg-gray-200 text-gray-700 rounded-lg">
            Galería
          </button>
        </div>
      </div>
    );
  };


export default function UserProfessionalPage() {
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("nextStep");
    localStorage.removeItem("authResponse");
    navigate(routesConfig.login);
  };
  return (
    <div className={`flex h-screen ${isDarkMode ? "dark" : ""}`}>
      <SidebarDashboard />
      <div className="flex-1 overflow-auto">
        <Header
          toggleSidebar={toggleSidebar}
          isDarkMode={isDarkMode}
          toggleDarkMode={toggleDarkMode}
        />
        <main className="flex-1 overflow-x-hidden overflow-y-auto p-4">
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
            <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="min-h-screen flex">
      <div className="w-1/4 p-4">
        <ProfileColumn />
      </div>
      <div className="w-1/4 p-4">
        <InfoColumn />
      </div>
      <div className="w-1/2 p-4">
        <DocumentationColumn />
      </div>
    </div>
            </div>

            
          </div>
        </main>
      </div>
    </div>
  );
}
