import { xervixApi } from '../config/axios.config';
import { API_ROUTES } from '../constants/apiRoutes';
import { ServiceResponse, ServiceWithAccessResponse } from '../types/service.types';

const fetchServices = async (): Promise<ServiceResponse> => {
  const response = await xervixApi.get(API_ROUTES.SERVICES.FETCH_SERVICES, {
    params: {
      page: 1,
      pageSize: 1000,
    },
  });
  return response.data as ServiceResponse;
};

const getAllBySubCategory = async (categoryId?: string, subCategoryId?: string): Promise<ServiceWithAccessResponse[]> => {
  
  const params: Record<string, string | undefined> = {};
  if (categoryId) params.categoryId = categoryId;
  if (subCategoryId) params.subCategoryId = subCategoryId;
  
  const response = await xervixApi.get(API_ROUTES.SERVICES.BY_CATEGORY_SUBCATEGORY, {params} );
  return response.data ;
};

// Bug in Backend
const fetchService= async (serviceId: string): Promise<ServiceResponse> => {
  const response = await xervixApi.get(API_ROUTES.SERVICES.FETCH_SERVICE(serviceId));
  return response.data as ServiceResponse;
};

const fetchTopServices = async (): Promise<ServiceResponse> => {
  const response = await xervixApi.get(API_ROUTES.SERVICES.FETCH_TOP_SERVICES);
  return response.data as ServiceResponse;
}

export default {
  fetchServices,
  fetchService,
  getAllBySubCategory,
  fetchTopServices
};
