import React, {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  SelectHTMLAttributes,
} from "react";
import { Field, ErrorMessage } from "formik";
import classNames from "classnames";
import { Switch } from "@headlessui/react";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  as?: never;
  options?: never;
  rows?: never;
  type?: string;
  placeholder?: string;
  toggleVisibility?: () => void;
  isPasswordVisible?: boolean;
};

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  name: string;
  as: "textarea";
  options?: never;
};

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label: string;
  name: string;
  as: "select";
  options: { value: string | number; label: string; disabled?: boolean }[];
  rows?: never;
};

type SwitchProps = {
  label: string;
  name: string;
  as: "switch";
  value: boolean;
  onChange: (value: boolean) => void;
};

type FormFieldProps = (
  | InputProps
  | TextareaProps
  | SelectProps
  | SwitchProps
) & {
  toggleVisibility?: () => void;
  isPasswordVisible?: boolean;
  className?: string;
  options?: { value: string | number; label: string; disabled?: boolean }[];
};

export const FormField: React.FC<FormFieldProps> = ({
  label,
  name,
  className,
  as,
  options,
  toggleVisibility,
  isPasswordVisible,
  ...props
}) => {
  const baseInputClasses =
    "mt-1 block w-full rounded-md border border-cBorder dark:border-dark-cBorder shadow-sm focus:border-blue-500 focus:ring-blue-500 placeholder-cTextSecondary font-light text-sm px-3 py-2 dark:bg-dark-cBackground dark:text-dark-cText dark:placeholder-dark-cTextSecondary";
  const labelClasses =
    "block text-sm font-light text-cTextSecondary dark:text-dark-cTextSecondary";
  const checkboxClasses =
    "h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded";

  const renderField = () => {
    if ("type" in props && props.type === "checkbox") {
      return (
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-cTextSecondary dark:text-dark-cTextSecondary">
            {label}
          </span>
          <Field
            name={name}
            type={"type" in props ? props.type : undefined}
            className={classNames(checkboxClasses, className)}
            {...props}
          />
        </div>
      );
    }

    if (name == "password" || name == "confirmPassword") {
      return (
        <div className="relative">
          <label className={labelClasses}>{label}</label>
          <div className="relative">
            <Field
              name={name}
              type={isPasswordVisible ? "text" : "password"}
              className={classNames(baseInputClasses, className, "pr-10")}
              {...props}
            />
            <button
              type="button"
              onClick={toggleVisibility}
              className="absolute inset-y-0 right-0 flex items-center px-3 py-2"
            >
              {isPasswordVisible ? "🙈" : "👁️"}
            </button>
          </div>
        </div>
      );
    }

    if (as === "select") {
      return (
        <>
          <label className={labelClasses}>{label}</label>
          <Field
            name={name}
            as={as}
            className={classNames(baseInputClasses, className)}
            {...props}
          >
            {options?.map((opt) => (
              <option
                key={opt.value}
                value={opt.value}
                disabled={opt.disabled || false}
              >
                {opt.label}
              </option>
            ))}
          </Field>
        </>
      );
    }

    if (as === "textarea") {
      return (
        <>
          <label className={labelClasses}>{label}</label>
          <Field
            name={name}
            as={as}
            className={classNames(baseInputClasses, className)}
            {...props}
          />
        </>
      );
    }

    if (as === "switch") {
      const switchProps = props as SwitchProps;
      return (
        <div className="flex items-center">
          <Switch
            checked={switchProps.value}
            onChange={switchProps.onChange}
            className={`${
              switchProps.value ? "bg-blue-600" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">{label}</span>
            <span
              className={`${
                switchProps.value ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform bg-white rounded-full`}
            />
          </Switch>
          <label className="ml-3 text-sm font-medium text-gray-700">
            {label}
          </label>
        </div>
      );
    }

    return (
      <>
        <label className={labelClasses}>{label}</label>
        <Field
          name={name}
          type={"type" in props ? props.type : undefined}
          placeholder={"placeholder" in props ? props.placeholder : undefined}
          className={classNames(
            baseInputClasses,
            className,
          )}
          {...props}
        />
      </>
    );
  };

  return (
    <div className="mb-4">
      {renderField()}
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm"
      />
    </div>
  );
};
