import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useCategoryStore from "../../store/useCategoryStore";
import { CategoryHierarchy } from "../../types/category.types";

interface CategorySearchInputProps {
  onSelect: (category: CategoryHierarchy) => void;
  onFilterCategories: (categoryId: string | null) => void;
  selectedCategories: any[];
}

const CategorySearchInput: React.FC<CategorySearchInputProps> = ({
  onSelect,
  onFilterCategories,
  selectedCategories,
}) => {
  const { t } = useTranslation();
  const { searchCategories } = useCategoryStore();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState<any[]>(
    []
  );
  const searchContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target as Node)
      ) {
        setFilteredResults([]);
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setFilteredResults([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchContainerRef]);

  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      const results = await searchCategories(query);
      if (Array.isArray(results) && results.length > 0) {
        const filtered = results.filter(
          (result) => !selectedCategories.includes(result.categoryId)
        );
        if (filtered.length > 0) {
          onFilterCategories(filtered[0].categoryId);
        }
      } else {
        onFilterCategories(null);
      }
    } else {
      onFilterCategories(null);
    }
  };
  /* const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      const results = await searchCategories(query);

      if (Array.isArray(results)) {
        // Filtramos los resultados para no mostrar las categorías ya seleccionadas
        const filtered = results.filter(
          (result) => !selectedCategories.includes(result.categoryId)
        );
        setFilteredResults(filtered); // Si es un array, lo asignas
        if (filtered.length > 0) {
          onFilterCategories(filtered[0].categoryId);
        }
      } else {
        setFilteredResults([]); // En caso de que no sea un array, reseteas el estado
        onFilterCategories(null);
      }
    } else {
      setFilteredResults([]); // Limpia los resultados si no hay búsqueda
      onFilterCategories(null);
    }
  }; */

  const handleAutocompleteSelect = (category: CategoryHierarchy) => {
    onSelect(category);
    setSearchQuery("");
    setFilteredResults([]);
    onFilterCategories(null);
    };

  return (
    <div className="text-center mt-4" ref={searchContainerRef}>
      <div className="relative max-w-md mx-auto">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={t("onboarding.categorySelection.searchPlaceholder")}
          className="px-4 py-2 border rounded-full w-full dark:bg-dark-cBackground dark:text-dark-cText pr-10 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
        />
        {/* {filteredResults.length > 0 && (
          <div className="absolute top-full mt-1 w-full max-w-md bg-white shadow-lg border border-gray-200 rounded-lg z-10">
            {filteredResults.map((result) => (
              <div
                key={result.categoryId}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleAutocompleteSelect(result)}
              >
                {result.serviceName} - {result.categoryName}
              </div>
            ))}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default CategorySearchInput;
