import React, { memo, useMemo } from "react";
import { CategoryCard } from "../../molecules/CategoryCard";
import { CategoryHierarchy } from "../../../types/category.types";

interface CategoriesGridSelectionProps {
  onSelect: (category: CategoryHierarchy) => void;
  categories: CategoryHierarchy[];
  selectedCategories: CategoryHierarchy[];
  loading?: boolean;
}

const LoadingSkeleton = () => (
  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
    {[...Array(8)].map((_, index) => (
      <div key={index} className="relative flex-none w-64 cursor-pointer select-none">
        <div className="p-4 xl:p-2 bg-gray-100 dark:bg-gray-800 rounded-3xl">
          <div className="bg-gray-300 dark:bg-gray-700 w-full h-60 xl:h-64 rounded-3xl animate-pulse" />
          <div className="mt-4 space-y-3">
            <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded animate-pulse" />
            <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-2/3 animate-pulse" />
          </div>
        </div>
      </div>
    ))}
  </div>
);

const CategoriesGridSelection: React.FC<CategoriesGridSelectionProps> = ({
  onSelect,
  categories,
  selectedCategories,
  loading,
}) => {

  const calculateTotalServices = useMemo(() =>
    (category: CategoryHierarchy) =>
      category.subCategories.reduce((acc, sub) => acc + sub.services.length, 0),
    []
  );

  return loading ? (
    <LoadingSkeleton />
  ) : (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"> {/* Adjust columns */}
      {categories &&
        categories.map((category: CategoryHierarchy) => (
          <CategoryCard
            key={category.categoryId}
            category={category}
            isNavigable={false}
            handleClick={() => onSelect(category)}
            isSelected={selectedCategories.some(cat => cat.categoryId === category.categoryId)}
            totalServices={calculateTotalServices(category)}
            loading={loading}
          />
        ))}
    </div>
  );
};

CategoriesGridSelection.displayName = "CategoriesGridSelection";

export default CategoriesGridSelection;
