interface IconProps {
  icon: React.ReactNode;
  active?: boolean;
}

export const Icon = ({ icon, active }: IconProps) => (
  <div
    className={`size-8 ${active ? "text-textBrand" : "text-cTextSecondary"}`}
  >
    {icon}
  </div>
);
