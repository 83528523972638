import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AppleIcon } from "../../../assets/icons/social-media/mac-os-logo.svg";
import { ReactComponent as GooglePlay } from "../../../assets/icons/social-media/google-play-logo.svg";
import Button from '../../atoms/Button';

interface AppDownloadHeroProps {
  onButtonClick: () => void;
}



const AppDownloadHero: React.FC<AppDownloadHeroProps> = ({ onButtonClick }) => {
  const { t } = useTranslation();

  const handleDownloadClick = () => {
    // Handle download logic
  };

  return (
    <section className="bg-cBackgroundSecondary dark:bg-dark-cBackgroundSecondary rounded-lg p-6">
      <div className="flex flex-col md:flex-row items-center justify-between h-[350px] max-w-6xl mx-auto">
        <div className="md:w-5/12 lg:w-4/12 mb-6 md:mb-0">
          <img
            src="/assets/img/landing-page/download-cellphone.png"
            alt="App Preview"
            className="w-full max-w-xs mx-auto md:mx-0 h-auto"
          />
        </div>

        <div className="md:w-7/12 lg:w-8/12 md:pl-8">
          <h2 className="text-2xl md:text-3xl font-bold mb-3 text-cText dark:text-dark-cText">
          {t("landingPage.appDownloadHero.title")}
          </h2>
          <h3 className="text-lg md:text-xl mb-4 font-medium">
            {t("landingPage.appDownloadHero.subtitle")}
          </h3>
          <p className="mb-6 text-cText dark:text-dark-cText leading-relaxed max-w-2xl">
            {t("landingPage.appDownloadHero.description")}
          </p>

          <div className="flex flex-col sm:flex-row gap-3">
            <Button
              onClick={onButtonClick}
              variant="filled"
              size="large"
              className="flex items-center justify-center px-5 py-2.5 rounded-lg transition-colors w-full sm:w-auto"
              
            >
              <AppleIcon className="size-5 mr-2" fill="white" />
              <div className="flex flex-col items-start">
                <span className="text-xs">
                  {t("landingPage.appDownloadHero.downloadOnThe")}
                </span>
                <span className="text-sm font-bold">App Store</span>
              </div>
            </Button>
            <Button
              onClick={onButtonClick}
              variant="filled"
              size="large"
              className="flex items-center justify-center bg-bgBrand hover:bg-bgBrandHover text-textOnBrand px-5 py-2.5 rounded-lg transition-colors w-full sm:w-auto"
            >
              <GooglePlay className="size-5 mr-2" />
              <div className="flex flex-col items-start">
                <span className="text-xs">
                  {t("landingPage.appDownloadHero.getItOn")}
                </span>
                <span className="text-sm font-bold">Google Play</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppDownloadHero;
