import { useEffect } from 'react';
import useAuthStore from '../store/useAuthStore';
import { UserRole } from '../types/auth.types';

export const useAuth = () => {
  const { initAuth, isAuthenticated, role, isLoading } = useAuthStore();

  useEffect(() => {
    initAuth();
  }, []);

  const checkAccess = (allowedRoles: UserRole[]) => {
    if (!isAuthenticated || !role) return false;

    // Convertir role a array si es string
    const userRoles: UserRole[] = Array.isArray(role) 
      ? role 
      : typeof role === 'string' 
        ? role.split(',').map(r => r.trim() as UserRole)
        : [role];

    return userRoles.some(userRole => allowedRoles.includes(userRole));
  };

  return {
    isAuthenticated,
    role,
    isLoading,
    checkAccess
  };
};