import { create } from 'zustand';

export type ModalType = 'confirmation' | 'service-completion' | 'information';

export interface ModalConfig {
  title?: string;
  terms?: boolean;
  message?: string;
  acceptButtonColor?: string;
  completeButtonText?: string;
  incompleteButtonText?: string;
  completeButtonColor?: string;
  incompleteButtonColor?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  additionalData?: any;
}

interface ModalStore {
  isOpen: boolean;
  modalType: ModalType | null;
  config: ModalConfig;
  openModal: (type: ModalType, config: ModalConfig) => void;
  closeModal: () => void;
}

export const useModalStore = create<ModalStore>((set) => ({
  isOpen: false,
  modalType: null,
  config: {},
  openModal: (type, config) => set({ isOpen: true, modalType: type, config }),
  closeModal: () => {
    set(state => {
      if (state.config.onClose) {
        state.config.onClose();
      }
      return { isOpen: false, modalType: null, config: {} };
    });
  },
}));