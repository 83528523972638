import { sign } from "crypto";
import { on } from "events";

const routesConfig = {
  home: '/',
  login: '/login',
  createAccount: '/create-account',
  createAccountForm: '/create-account/user/form',
  createAccountCompany: '/create-account/company',
  createAccountCompanyForm: '/create-account/company/form',
  splash: '/splash',
  validateEmail: '/validate-email/:sendCodeTo',

  onboardingProfessionalPage: '/onboarding-professional',
  categoryOnboarding: '/category-onboarding',
  serviceOnboarding: '/service-onboarding',
  localizationOnboarding: '/localization-onboarding',
  businessOnboarding: '/business-onboarding',
  termsAndConditions: '/terms-and-conditions-onboarding',
  successCompletedOnboarding: '/success-completed-onboarding',

  // Professional
  dashboardProfessional: '/professional',
  userProfessional: '/professional/user',
  chatProfessional: '/professional/chat',
  servicesProfessional: '/professional/services',
  proposalsProfessional: '/professional/proposals',
  marketplaceProfessional: '/professional/marketplace',
  paymentsProfessional: '/professional/payments',
  configProfessional: '/professional/config',

  mainRequests: '/main-requests',
  myReels: '/myreels',
  //admin
  AdminPage: '/admin',
  AdminPageUser: '/admin/user',
  AdminPageEmpresas: '/admin/empresas',
  AdminPageSolicitudes: '/admin/solicitudes',
  AdminPageOpiniones: '/admin/opiniones',
  AdminPageMarketPlace: '/admin/marketplace',
  AdminPageHistorial: '/admin/pagos',
  AdminPagePagosGanancias: '/admin/pagos/ganancias',
  AdminPagePagospendientes: '/admin/pagos/pendientes',
  AdminPageEstadisticas: '/admin/estadisticas',
  AdminDetailCompanyPage: '/admin/empresas/idempresa',
  AdminRequestClose: '/admin/request/close',
  AdminRequestResume: '/admin/request/resume',
  AdminPageOpinionesHistorial: '/admin/opiniones/historial',
  AdminPageReelsHistorial: '/admin/marketplace/resume',

  //finaliza admin
  AdminPageDashboard: '/admin',
  mainServices: '/services',
  subscriptionProfessionalPage: '/subscription-professional-page',
  termsAndConditionsPage: '/terms-and-conditions-xervix',
  signupCustomerPage: '/signup-customer-page',
  categoriesPage: '/categories',
  categoryServicesPage: '/category/:id',
  serviceForm: '/service-form/:categoryId/:subCategoryId',
  myRequests: '/requests',
  myServices: '/services',
};
export default routesConfig;
