import React, { useEffect, useRef, useMemo } from "react";
import useCategoryStore from "../../../store/useCategoryStore";
import { CategoryHierarchy } from "../../../types/category.types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useMediaQuery } from "react-responsive";
import { useSliderStore } from "../../../store/useSliderStore";
import { CategoryCard } from "../../molecules/CategoryCard";
import { useNavigate } from 'react-router-dom';
import routesConfig from '../../../config/routes.config';
import CardSkeleton from "../../../components/atoms/CardSkeleton";


const CategoriesSlider: React.FC = () => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { scroll, startDragging, stopDragging, onDrag } = useSliderStore();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const { categoryHierarchy, fetchCategoriesHierarchy, isLoading } = useCategoryStore();

  useEffect(() => {
    fetchCategoriesHierarchy();
  }, [fetchCategoriesHierarchy]);

  const calculateTotalServices = useMemo(() =>
    (category: CategoryHierarchy) =>
      category.subCategories.reduce((acc, sub) => acc + sub.services.length, 0),
    []
  );

  const handleCardClick = (category: CategoryHierarchy) => {
    navigate(routesConfig.categoryServicesPage.replace(':id', category.categoryId), {
      state: { category }
    });
  };

  if (isLoading) {
    return (
      <div className="flex gap-4 overflow-x-auto pb-4">
        {[...Array(6)].map((_, index) => (
          <CardSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (!categoryHierarchy?.length) {
    return (
      <div className="text-center py-8 text-gray-500 dark:text-gray-400">
        No categories available
      </div>
    );
  }

  return (
    <div className="max-w-screen-3xl ">
      <div className="relative">
        {!isTabletOrMobile && (
          <button
            onClick={() => scroll("left")}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-cBackground dark:bg-dark-cBackgroundSecondary dark:shadow-dark-cBackground rounded-full p-2 shadow-md"
            aria-label="Scroll left"
          >
            <ChevronLeftIcon className="size-2 xl:size-6 text-cTextSecondary dark:text-dark-cTextSecondary" />
          </button>
        )}
        <div
          ref={sliderRef}
          className="slider-container flex overflow-x-auto scrollbar-hide space-x-4 py-4 no-scrollbar px-2 xl:px-2"
          onMouseDown={isTabletOrMobile ? startDragging : undefined}
          onMouseLeave={isTabletOrMobile ? stopDragging : undefined}
          onMouseUp={isTabletOrMobile ? stopDragging : undefined}
          onMouseMove={isTabletOrMobile ? onDrag : undefined}
        >
          {categoryHierarchy &&
            categoryHierarchy
              .slice(0, 7)
              .map((category: CategoryHierarchy, index: number) => (
                <CategoryCard
                  key={category.categoryId}
                  category={category}
                  isNavigable={true}
                  totalServices={calculateTotalServices(category)}
                  handleClick={handleCardClick}
                  loading={false}
                />
              ))}
        </div>
        {!isTabletOrMobile && (
          <button
            onClick={() => scroll("right")}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-cBackground dark:bg-dark-cBackgroundSecondary dark:shadow-dark-cBackground rounded-full p-2 shadow-lg z-10"
            aria-label="Scroll right"
          >
            <ChevronRightIcon className="size-2 xl:size-6 text-cTextSecondary dark:text-dark-cTextSecondary" />
          </button>
        )}
      </div>
    </div>
  );
};

export default CategoriesSlider;
