import { useNavigate, useLocation } from 'react-router-dom';
import useAuthStore from '@/store/useAuthStore';
import routesConfig from '@/config/routes.config';

const UserRoleNavigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, role } = useAuthStore();

  const hasMultipleRoles = (roles?: string[]) => {
    if (!roles || !Array.isArray(roles)) return false;
    return roles.includes('USER') && roles.includes('PROFESSIONAL');
  };

  if (!isAuthenticated || !hasMultipleRoles(role as unknown as string[] | undefined)) {
    return null;
  }
  const isUserMode = !location.pathname.includes('dashboard');

  return (
    <div className="w-full flex justify-center border-b border-gray-200 pb-4">
        <div className="flex">
        <div
          onClick={() => navigate(routesConfig.home)}
          className={`px-6 py-2 cursor-pointer transition-colors ${
            isUserMode 
              ? 'border-bgBrand text-bgBrand' 
              : 'border-transparent hover:border-gray-300'
          }`}
        >
          Navegar como Usuario
        </div>
        <div className="w-px bg-gray-200" />
        <div
          onClick={() => navigate(routesConfig.dashboardProfessional)}
          className={`px-6 py-2 cursor-pointer transition-colors ${
            !isUserMode 
              ? 'border-bgBrand text-bgBrand' 
              : 'border-transparent hover:border-gray-300'
          }`}
        >
          Navegar como Empresa
        </div>
      </div>
    </div>
  );
};

export default UserRoleNavigation;