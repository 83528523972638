import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../atoms/Button";

const ViewAllButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="w-full text-right pt-2">
      <Button
        onClick={() => navigate("/categories", { state: { isDashboard: false } })}
        variant="outlined"
        size="medium"
        className="text-[#F97068] hover:text-[#F97068] lg:w-max pr-4 text-sm md:text-base lg:font-medium"
      >
        {t("landingPage.viewAllButton")}
      </Button>
    </div>
  );
};

export default ViewAllButton;