import React, { useState } from 'react';
import MessagesTab from '../components/molecules/sidebarRequest/MessagesTab';
import MarketplaceTab from '../components/molecules/sidebarRequest/MarketplaceTab';
import ReviewsTab from '../components/molecules/sidebarRequest/ReviewsTab';
import RequestsSection from '../components/molecules/sidebarRequest/RequestsSection';
import ProposalsSection from '../components/molecules/sidebarRequest/ProposalsSection';
import { BaseLayout } from '../components/templates/BaseLayout';

const MyRequestsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('requests');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'requests':
        return <RequestsSection />;
      case 'proposals':
        return <ProposalsSection />;
      case 'messages':
        return <MessagesTab />;
      case 'marketplace':
        return <MarketplaceTab />;
      case 'reviews':
        return <ReviewsTab />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full grid grid-cols-2 divide-x divide-gray-200 gap-4">
     <RequestsSection />
     <ProposalsSection />
    </div>
  );
};

export default MyRequestsPage;