import React, { useEffect, useState } from "react";

import useServiceStore from "../../store/useServiceStore";
import { useNavigate } from "react-router-dom";
import { Service } from "../../types/service.types";
import routesConfig from '../../config/routes.config';
import { useTranslation } from "react-i18next";
import { ServiceCard } from "./TopServiceCard";
import SearchField from './SearchField';


const ServiceSearchComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const { services, isLoading, error, fetchServices } = useServiceStore();
  // const subCategories: SubCategory[] = categoryHierarchy.flatMap(
  //   (category: CategoryHierarchy) => category.subCategories
  // );

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    console.log("Buscar:", searchTerm);
  };

  const handleRequestClick = (service: Service) => {
    console.log("Enviar Solicitud para:", service.name);
    navigate(routesConfig.serviceForm.replace(':id', service.serviceId));
  }

  const filteredServices = services?.data.filter((service: Service) =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())) || [];

  return (
    <div>
      <SearchField
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchClick={handleSearchClick}
        placeholder={t('landingPage.serviceSearch.searchPlaceholder')}
      />

      {/* {isLoading && <p className="text-center mt-4">Cargando servicios...</p>}
      {error && <p className="text-center mt-4 text-red-500">{error}</p>} */}

      {searchTerm && filteredServices.length > 0 && (
          <section className="my-6">
          <h2 className="text-3xl font-bold mb-6">{t('landingPage.serviceSearch.searchResults')}</h2>
          <div className="flex overflow-x-auto space-x-4 pb-2 [&::-webkit-scrollbar]:w-2
                                                          [&::-webkit-scrollbar]:h-2
                                                          [&::-webkit-scrollbar]:rounded-full
                                                          [&::-webkit-scrollbar]:bg-gray-100
                                                          [&::-webkit-scrollbar-thumb]:rounded-full
                                                          [&::-webkit-scrollbar-thumb]:bg-gray-300
                                                          [&::-webkit-scrollbar-track]:rounded-full
                                                          [&::-webkit-scrollbar-track]:bg-gray-100
                                                          dark:[&::-webkit-scrollbar-track]:bg-dark-cBackgroundSecondary
                                                          dark:[&::-webkit-scrollbar-thumb]:bg-dark-cTextSecondary">
            {filteredServices.map((service, index) => (
              <div key={index} className="flex-none w-80">
                <ServiceCard key={index} service={service} onRequestClick={handleRequestClick} />
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default ServiceSearchComponent;
