// Alternative as enum if preferred
export enum UserRole {
  USER = 'USER',           // Regular end user
  PROFESSIONAL = 'PROFESSIONAL', // Service provider
  ADMIN = 'ADMIN'         // System administrator
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface RegisterRequest {
  name: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  userType: UserRole; // "USER" o "PROFESSIONAL"
}

export interface SignUpFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface GoogleLoginRequest {
  token: string;
}

export interface VerifyCodeRequest {
  code: string;
  emailOrPhone: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  nextStep: number;
  expiresAt: string;
  isConfirmed: boolean;
  userType: UserRole[] | UserRole;
}

export interface AuthState {
  user: any | null;
  role: UserRole | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  nextStep: number | null;
  initAuth: () => void;
  login: (email: string, password: string) => Promise<LoginResponse>;
  facebookLogin: (token: string) => Promise<LoginResponse>;
  googleLogin: (token: string) => Promise<LoginResponse>;
  googleRegister: (token: string, userType: string) => Promise<void>;
  register: (name: string, lastName: string, email: string, password: string, phoneNumber: string, userType: string | null) => Promise<LoginResponse>;
  verifyCode: (code: string, emailOrPhone: string) => Promise<void>;
  resendVerificationCode: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  logout: () => void;
}

export interface DecodedToken {
  role: string | string[];  // Modificado para aceptar un array de roles
  exp: number;
  name: string;
  lastName: string;
}