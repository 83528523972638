import React, { useRef, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/social-media/google-logo.svg';
import { useTranslation } from 'react-i18next';


interface Props {
  onSuccess: (credentialResponse: any) => void;
}

export const GoogleAuthButton = ({ onSuccess }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="relative w-full h-12">
      <button
        className='w-full h-12 flex items-center justify-center gap-3 px-6
        bg-cBackground dark:bg-dark-cBackground hover:bg-cBackgroundSecondary dark:hover:bg-dark-cBackgroundSecondary
        border border-cBorder dark:border-dark-cBorder
        rounded-xl
        transition-all duration-200'
        >
          <GoogleIcon className='size-6' />
          {t('google')}
      </button>

      <div className='absolute top-0 left-0 w-full h-full opacity-0 pointer-events-auto z-0'>
        <GoogleLogin
          onSuccess={onSuccess}
          onError={() => console.log('Login Failed')}
          useOneTap={false}
          theme='outline'
          size="large"
          text="continue_with"
          shape="rectangular"
          width="100%"
        />
      </div>
    </div>
  );
};