
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const RequestsSection: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRequest, setSelectedRequest] = useState<string | null>(null);
  const handleServiceButtonClick = () => {
    navigate('/categories');
  }

  // Mock data - replace with actual data from your API
  const requests: any[] = [
    {id:'1', img:'', category: 'Mascotas', subcategory: 'Guardería para perro', service: 'Cambridge Pets', date: '28 de may. de 2025', proposals: 6},
    {id:'2', img:'', category: 'Hogar', subcategory: 'Servicio de limpieza', service: 'Casa Limpia', date: '28 de may. de 2025', proposals: 6},
  ];

  return (
    <section>
      <h2 className="text-lg font-medium text-center mb-6">Mis Solicitudes</h2>
      {requests.length === 0 ? (
        <div className="text-center">
          <img src="assets/icons/brand/no-requests.png" alt="No requests" className="mx-auto mb-4 w-48" />
          <h3 className="text-xl font-semibold mb-2">{t('requestsAndProposals.noRequests.title')}</h3>
          <p className="text-gray-600">{t('requestsAndProposals.noRequests.description')}</p>
        </div>
      ) : (
        <div className="grid gap-4">
          {requests.map((request) => (
            <div 
              key={request.id}
              className={`p-4 rounded-lg transition-all ${
                selectedRequest === request.id 
                  ? 'border-2 border-bgBrand' 
                  : 'shadow-md'
              }`}
              onClick={() => setSelectedRequest(request.id)}
            >
              <div className='flex flex-row gap-8 justify-between p-4 text-cTextSecondary'>
                <img src={request.img} alt="img" />
                <div>
                  <h1 className='font-medium text-cText dark:text-dark-cText'>{request.category}</h1>
                  <p>{request.subcategory}</p>
                  <p>{request.service}</p>
                </div>
                <div>
                  <p>{request.proposals} proposals</p>
                  <p>{request.date}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

    <div className="mt-8 border border-gray-300 rounded-lg p-6">
      <p className="text-cTextSecondary text-center">
        {t('requestsAndProposals.infoBox.text')}
      </p>
      <div className="flex justify-center">
        <button className="mt-4 bg-bgBrand text-white px-6 py-2 rounded-lg hover:bg-bgBrandHover transition-colors"
        onClick = {handleServiceButtonClick}>
          {t('requestsAndProposals.infoBox.button')}
        </button>
      </div>
    </div>
    </section>
  );
};

export default RequestsSection;