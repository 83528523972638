import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { FormField } from "@/components/atoms/FormField";

import { NextStepGuardRouteString } from "@/guards/NextStepGuard";
import routesConfig from "@/config/routes.config";
import useAuthStore from "@/store/useAuthStore";

import EmailAuthButton from "@/components/molecules/auth/EmailAuthButton";
import { GoogleAuthButton } from "@/components/molecules/auth/GoogleAuthButton";
import FacebookAuthButton from "@/components/molecules/auth/FacebookAuthButton";
import { on } from "events";

interface LoginModalProps {
  onClose?: () => void;
  onSuccess?: () => void;
  description?: string;
}

const LoginForm: React.FC<LoginModalProps> = ({
  onClose,
  onSuccess,
  description,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { googleLogin, login, facebookLogin } = useAuthStore();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("loginPage.email.validEmail"))
      .required(t("loginPage.email.required")),
    password: Yup.string().required(t("loginPage.password.required")),
  });

  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      await login(values.email, values.password);
      if (onSuccess) {
        onSuccess();
      } else {
        const nextStep = localStorage.getItem("nextStep");
        if (nextStep === "0" || nextStep === "9") {
          navigate(routesConfig.home);
        } else {
          const redirectTo = NextStepGuardRouteString();
          navigate(redirectTo);
        }
      }
    } catch (error: any) {
      console.error("Login failed:", error);
    }
  };

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      const response = await googleLogin(credentialResponse.credential);
      if (onSuccess) {
        onSuccess();
      } else {
        const redirectTo =
          response.userType === "PROFESSIONAL"
            ? routesConfig.dashboardProfessional
            : routesConfig.home;
        navigate(redirectTo);
      }
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  const handleFacebookLoginSuccess = async (credentialResponse: any) => {
    try {
      const response = await facebookLogin(credentialResponse.accessToken);
      if (onSuccess) {
        onSuccess();
      } else {
        const redirectTo =
          response.userType === "PROFESSIONAL"
            ? routesConfig.dashboardProfessional
            : routesConfig.home;
        navigate(redirectTo);
      }
    } catch (error) {
      console.error("Facebook login error:", error);
    }
  };

  const handleFacebookLoginFailure = (error: any) => {
    console.error("Error al iniciar sesión con Facebook:", error);
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <>
      <h1 className="text-2xl font-bold mb-6">{t("loginPage.welcome")}</h1>
      {description && (
        <p className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary">
          {description}
        </p>
      )}
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="w-full max-w-sm">
            <FormField
              label={t("loginPage.email.label")}
              name="email"
              type="email"
              placeholder={t("loginPage.email.placeholder")}
            />
            <FormField
              label={t("loginPage.password.label")}
              name="password"
              type={passwordVisible ? "text" : "password"}
              placeholder={t("loginPage.password.placeholder")}
              toggleVisibility={() => setPasswordVisible(!passwordVisible)}
              isPasswordVisible={passwordVisible}
            />
            <EmailAuthButton type="submit" isSubmitting={isSubmitting}>
              {isSubmitting ? t("loading") : t("loginPage.loginButton")}
            </EmailAuthButton>
          </Form>
        )}
      </Formik>
      <p className="mt-6 text-sm text-cTextSecondary dark:text-dark-cTextSecondary">
        {t("loginPage.noAccount")}{" "}
        <a
          href={routesConfig.createAccount}
          className="text-blue-500 hover:underline"
        >
          {t("loginPage.createAccount")}
        </a>
      </p>
      <div className="my-4 flex items-center justify-center w-full">
        <span className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary">
          {t("loginPage.orLoginWith")}
        </span>
      </div>
      <div className="flex flex-col gap-4 w-full max-w-sm">
        <GoogleAuthButton onSuccess={handleGoogleSuccess} />
        <FacebookAuthButton
          onSuccess={handleFacebookLoginSuccess}
          onFailure={handleFacebookLoginFailure}
        />
      </div>
    </>
  );
};

export default LoginForm;
