import React, { useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuthStore from "../../store/useAuthStore";
import routesConfig from "../../config/routes.config";
import { NextStepGuardRouteString } from "../../guards/NextStepGuard";
import Button from "../../components/atoms/Button";
import { ThemeToggle } from "../../components/atoms/ThemeToggle";
import { LanguageToggle } from "../../components/atoms/LanguageToggle";

const VerifyCodePage: React.FC = () => {
  const { t } = useTranslation();
  const { sendCodeTo } = useParams<{ sendCodeTo: string }>();
  const [code, setCode] = useState(["", "", "", "", "", ""]); // Updated for 6 digits
  const { verifyCode, resendVerificationCode, isLoading } = useAuthStore();
  const navigate = useNavigate();

  // Create refs for each input to control focus
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  // Handle changes in the verification code inputs
  const handleCodeChange = (value: string, index: number) => {
    const newCode = [...code];
    newCode[index] = value;

    if (value !== "" && index < 5) {
      inputRefs.current[index + 1]?.focus(); // Move focus to the next input
    }
    setCode(newCode);
  };

  // Verify the entered code
  const handleVerifyCode = async () => {
    const codeString = code.join("");

    try {
      await verifyCode(codeString, sendCodeTo || "");
      const userType = localStorage.getItem("role");
      console.log("User Type: ", userType);
      localStorage.setItem("nextStep", "1");
      if (userType?.includes('PROFESSIONAL')) {
        navigate(NextStepGuardRouteString());
      } else {
        navigate(routesConfig.home);
      }
    } catch (error) {
      console.error("Error verifying code:", error);
    }
  };

  // Resend the verification code
  const handleResendCode = async () => {
    try {
      await resendVerificationCode();
      alert(t("verifyCodePage.codeResent"));
    } catch (error) {
      console.error("Error resending code:", error);
    }
  };

  return (
    <div className="flex min-h-screen dark:bg-dark-cBackground">
      <div className="absolute top-4 right-4 flex items-center gap-2 z-10">
        <ThemeToggle />
        <LanguageToggle />
      </div>
      <div
        className="hidden md:flex md:w-2/3 bg-cover bg-center relative"
        style={{
          backgroundImage: "url(/assets/img/backgrounds/login-side-img.jpeg)",
        }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-start top-16">
          <h1 className="text-6xl font-bold mb-4 text-bgBrand">XERVIX</h1>
          <p className="text-2xl font-light text-dark-cText">
            {t("loginPage.services")}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center md:w-1/3 w-full p-8">
        <h1 className="text-2xl font-bold text-center mb-4">
          {t("verifyCodePage.title")}
        </h1>
        <p className="text-center mb-6">
          {t("verifyCodePage.instructions", { email: sendCodeTo })}
        </p>
        <div className="flex justify-center gap-2 mb-4">
          {code.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleCodeChange(e.target.value, index)}
              className="w-12 h-12 border border-gray-300 text-center text-xl rounded-md"
              ref={(el) => (inputRefs.current[index] = el)} // Store input ref
              onKeyDown={(e) => {
                if (e.key === "Backspace" && digit === "" && index > 0) {
                  inputRefs.current[index - 1]?.focus(); // Move focus to the previous input if backspace
                }
              }}
            />
          ))}
        </div>
        <Button
          onClick={handleVerifyCode}
          variant="filled"
          size="large"
          className="w-full mb-4"
          disabled={isLoading || code.some((digit) => digit === "")}
        >
          {isLoading
            ? t("verifyCodePage.verifying")
            : t("verifyCodePage.verifyButton")}
        </Button>
        <p className="text-center text-sm">
          {t("verifyCodePage.didNotReceive")}
          <Button
            onClick={handleResendCode}
            variant="outlined"
            size="small"
            className="text-blue-500 ml-1"
            disabled={isLoading}
          >
            {isLoading
              ? t("verifyCodePage.sending")
              : t("verifyCodePage.resendLink")}
          </Button>
        </p>
      </div>
    </div>
  );
};

export default VerifyCodePage;
