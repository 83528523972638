// src/components/DetalleServicio.tsx
import React from "react";

interface DetailServiceProps {
  servicio: {
    id: number;
    categoria: string;
    descripcion: string;
    fecha: string;
    proveedor: string;
    imagen: string;
  };
  onBack: () => void;
}

const DetailService: React.FC<DetailServiceProps> = ({ servicio, onBack }) => {
  return (
    <div className="p-6 bg-gray-100 min-h-screen flex flex-col items-center">
      <div className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-md">
        <div className="flex">
          <img src={servicio.imagen} alt={servicio.categoria} className="rounded-lg w-1/2 h-64 object-cover" />
          <div className="ml-6 flex-1">
            <h3 className="text-xl font-bold mb-2">{servicio.proveedor}</h3>
            <p className="text-gray-700 text-sm">{servicio.descripcion}</p>
            <p className="text-gray-500 text-sm mt-4">Fecha de la solicitud: {servicio.fecha}</p>
            <button
              className="mt-4 px-6 py-2 bg-red-500 text-white rounded-lg"
              onClick={onBack}
            >
              Volver
            </button>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-2 gap-4">
          <div>
            <label className="text-sm font-semibold text-gray-700">Número de la solicitud</label>
            <p className="text-gray-800">254065124</p>
          </div>
          <div>
            <label className="text-sm font-semibold text-gray-700">Precio aprobado</label>
            <p className="text-gray-800">$2.700.000</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailService;
