import React, { useState } from "react";
import Modal from "../../../components/Modals/Modal";

interface Company {
  id: number;
  user: string;
  service: string;
  category: string;
  fecha: Date;
  cierre: string;
  idEmpresas: number;
  propuestas: number;
  telefono: string;
  email: string;
  accion: string;
}

const TableActiveRequest: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([
    { id: 2356, user: "Juan Camilo", service: "Guardería de mascotas", category: "Mascotas", fecha: new Date("2024-06-25"), cierre: "2 días", idEmpresas: 12, propuestas: 5, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
    { id: 2564, user: "Esteban", service: "Lavado de carro", category: "Automotriz", fecha: new Date("2024-06-28"), cierre: "1 día", idEmpresas: 65, propuestas: 7, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
    { id: 2444, user: "Diego Alejandro", service: "Corte de cabello", category: "Belleza", fecha: new Date("2024-06-27"), cierre: "8 horas", idEmpresas: 45, propuestas: 8, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
    { id: 2699, user: "Estefanía Garzes", service: "Cita médica", category: "Salud", fecha: new Date("2024-06-25"), cierre: "1 hora", idEmpresas: 63, propuestas: 5, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
    { id: 2554, user: "Carlos Perez", service: "Guardería de mascotas", category: "Mascotas", fecha: new Date("2024-06-20"), cierre: "2 horas", idEmpresas: 12, propuestas: 1, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
    { id: 2357, user: "Carlos Perez", service: "Guardería de mascotas", category: "Mascotas", fecha: new Date("2024-06-20"), cierre: "2 horas", idEmpresas: 12, propuestas: 1, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
    { id: 2657, user: "Carlos Perez", service: "Guardería de mascotas", category: "Mascotas", fecha: new Date("2024-06-20"), cierre: "2 horas", idEmpresas: 12, propuestas: 1, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
    { id: 6357, user: "Carlos Perez", service: "Guardería de mascotas", category: "Mascotas", fecha: new Date("2024-06-20"), cierre: "2 horas", idEmpresas: 12, propuestas: 1, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
    { id: 7357, user: "Carlos Perez", service: "Guardería de mascotas", category: "Mascotas", fecha: new Date("2024-06-20"), cierre: "2 horas", idEmpresas: 12, propuestas: 1, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
    { id: 2577, user: "Carlos Perez", service: "Guardería de mascotas", category: "Mascotas", fecha: new Date("2024-06-20"), cierre: "2 horas", idEmpresas: 12, propuestas: 1, telefono: "31465896554", email: "juancamilogo,@gmail.com", accion: "Buscar" },
  ]);

  const sortCompanies = (criteria: string) => {   
    const sortedCompanies = [...companies];

    switch (criteria) {
      case "fecha":
        sortedCompanies.sort((a, b) => a.fecha.getTime() - b.fecha.getTime());
        break;
      case "cierre":
        sortedCompanies.sort((a, b) => {
          const parseTime = (time: string) => {
            const [value, unit] = time.split(" ");
            if (!value || !unit) return 0;
            const multiplier = unit.startsWith("hora") ? 1 : unit.startsWith("día") ? 24 : 0;
            return parseInt(value) * multiplier;
          };
          return parseTime(a.cierre) - parseTime(b.cierre);
        });
        break;
      case "propuestas":
        sortedCompanies.sort((a, b) => a.propuestas - b.propuestas);
        break;
      case "idEmpresas":
        sortedCompanies.sort((a, b) => b.idEmpresas - a.idEmpresas);
        break;
      default:
        break;
    }

    setCompanies(sortedCompanies);
    
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=""> 
      <Modal 
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Empresas sin envío de propuesta"
      >
        <div className="text-center">        
          <p className="text-xs text-gray-500 mb-8">
            Contactar con la mayor cantidad de empresas para hacer seguimiento a la solicitud
          </p>
          
          <div className="space-y-4">
            <div className="flex justify-between text-xs">
              <h5>Empresa</h5>
              <h5>300 5456325</h5>
            </div>     
            <div className="flex justify-between text-xs">
              <h5>Empresa 2</h5>
              <h5>310 53655421</h5>
            </div>    
            <div className="flex justify-between text-xs">
              <h5>Empresa 3</h5>
              <h5>300 2356669</h5>
            </div>    
            <div className="flex justify-between text-xs">
              <h5>Empresa 4</h5>
              <h5>300 2655545</h5>
            </div>    
            <div className="flex justify-between text-xs">
              <h5>Empresa 5</h5>
              <h5>300 3555658</h5>
            </div>            
          </div>
        
          <button
            className="w-36 mt-4 bg-[#F97068] hover:bg-red-500 text-white px-4 py-1 rounded-lg text-sm"
            onClick={handleCloseModal}
          >
            Aceptar
          </button>
        </div>
      </Modal>

      <h1 className="text-lg font-bold text-center">Seguimiento de Solicitudes Activas</h1>
      <div className="flex items-center gap-4 mb-4">
        <label htmlFor="sort" className="text-sm font-medium">
          Ordenar por:
        </label>
        <select
          id="sort"
          className="border rounded p-2 text-sm"
          onChange={(e) => sortCompanies(e.target.value)}
        >
          <option value="">Selecciona un criterio</option>
          <option value="fecha">Fecha</option>
          <option value="cierre">Cierre</option>
          <option value="propuestas">Propuestas</option>
          <option value="idEmpresas">Empresas</option>
        </select>
      </div>
      <div className="overflow-y-auto max-h-96 border rounded-md">
        <table className="table-auto w-full border-collapse border border-gray-200 max-h-96">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-3 text-xs text-left">ID</th>
              <th className="px-4 py-3 text-xs text-center">Usuario</th>
              <th className="px-4 py-3 text-xs text-center">Servicio</th>
              <th className="px-4 py-3 text-xs text-center">Categoría</th>
              <th className="px-4 py-3 text-xs text-center">Fecha</th>
              <th className="px-4 py-3 text-xs text-center">Cierre</th>
              <th className="px-4 py-3 text-xs text-center">Empresas</th>
              <th className="px-4 py-3 text-xs text-center">Propuestas</th>
              <th className="px-4 py-3 text-xs text-center">Teléfono</th>
              <th className="px-4 py-3 text-xs text-center">Email</th>
              <th className="px-4 py-3 text-xs text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company) => (
              <tr key={company.id} className="hover:bg-gray-50 border-t">
                <td className="px-4 py-4 text-xs text-left text-gray-700">{company.id}</td>
                <td className="px-4 py-4 text-xs text-center text-gray-700">{company.user}</td>
                <td className="px-4 py-4 text-xs text-center text-gray-700">{company.service}</td>
                <td className="px-4 py-4 text-xs text-center text-gray-700">{company.category}</td>
                <td className="px-4 py-4 text-xs text-center text-gray-700">{company.fecha.toLocaleDateString("es-CO")}</td>
                <td className="px-4 py-4 text-xs text-center text-gray-700">{company.cierre}</td>
                <td className="px-4 py-4 text-xs text-center text-gray-700">{company.idEmpresas}</td>
                <td className="px-4 py-4 text-xs text-center text-gray-700">{company.propuestas}</td>
                <td className="px-4 py-4 text-xs text-center text-gray-700">{company.telefono}</td>
                <td className="px-4 py-4 text-xs text-center text-gray-700">{company.email}</td>            

                <td className="px-4 py-4 text-xs text-center text-red-500"><button onClick={() => setIsModalOpen(true)}>
                  Buscar Empresas
                </button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableActiveRequest;

