import { create } from 'zustand';
import categoryService from '../services/category.service';
import professionalService from '../services/professional.service';
import { CategoriesResponse, CategoryResponse, CategoryQuerySearchResponse, CategoryHierarchy } from '../types/category.types';

interface CategoryStoreState {
  categories: CategoriesResponse | null;
  searchResults: CategoryQuerySearchResponse[];
  availableServices: any[]; // Estado para almacenar los servicios disponibles
  categoryHierarchy: CategoryHierarchy[] | null;
  isLoading: boolean;
  error: string | null;
  fetchCategories: () => Promise<void>;
  fetchCategory: (categoryId: string) => Promise<CategoryResponse | undefined>;
  searchCategories: (query: string) => Promise<any[]>;
  fetchAvailableServices: () => Promise<void>; // Método para obtener los servicios disponibles
  fetchCategoriesHierarchy: () => Promise<void>;
}

const useCategoryStore = create<CategoryStoreState>((set) => ({
  categories: null,
  searchResults: [],
  availableServices: [],
  categoryHierarchy: null,
  isLoading: false,
  error: null,

  fetchCategories: async () => {
    set({ isLoading: true });
    try {
      const categories = await categoryService.fetchCategories();
      set({ categories, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },

  fetchCategory: async (categoryId: string): Promise<CategoryResponse | undefined> => {
    set({ isLoading: true });
    try {
      const category = await categoryService.fetchCategory(categoryId);
      return category || [];
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({error: error.message, isLoading: false});
      } else {
        set({error: 'An unknown error ocurred', isLoading: false})
      }
    }
  },

  searchCategories: async (query: string): Promise<any[]> => {
    set({ isLoading: true });
    try {
      const results = await categoryService.searchCategories(query);
      set({ isLoading: false });
      return results || []; // Devuelve los resultados directamente
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
      return [];
    }
  },

  fetchAvailableServices: async () => {
    set({ isLoading: true });
    try {
      const availableServices = await professionalService.fetchAvailableServices();
      set({ availableServices, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },

  fetchCategoriesHierarchy: async () => {
    set({ isLoading: true });
    try {
      const categoryHierarchy = await categoryService.fetchCategoriesHierarchy();
      set({ categoryHierarchy, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },
}));

export default useCategoryStore;
