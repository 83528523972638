import React from 'react';

interface StepperProps {
  currentStep: number;
}

const Stepper: React.FC<StepperProps> = ({  currentStep }) => {

  const steps = [
    '1',
    '2',
    '3',
    '4',
    '5',
  ];

  return (

      <div className="mb-6">
        {/* Texto que muestra el número de paso actual */}
        <p className="text-sm font-semibold mb-2">Paso {currentStep}</p>
        {/* Contenedor de las barras en fila */}
        <div className="flex items-center gap-2">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`
                h-1.5
                rounded-full
                ${index === currentStep - 1
                  ? 'bg-bgBrand w-28' // Barra activa o "actual"
                  : 'bg-cTextSecondary opacity-40 w-14' // Barras inactivas
                }
              `}
            />
          ))}
        </div>
      </div>
  );
};

export default Stepper;