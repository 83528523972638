import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ArrowsUpDownIcon, StarIcon as StarOutline } from "@heroicons/react/24/outline";
import { StarIcon as StarFilled } from '@heroicons/react/24/solid';
import useServiceStore from "../../../store/useServiceRequestStore";


const StarRating = ({ rating }: { rating: number }) => {
  const totalStars = 5;
  const fullStars = Math.floor(rating);
  const partialStar = rating % 1;
  const emptyStars = totalStars - fullStars - (partialStar > 0 ? 1 : 0);

  return (
    <div className="flex flex-row items-center">
      {/* Full stars */}
      {Array.from({ length: fullStars }).map((_, index) => (
        <StarFilled key={`full-${index}`} className="w-4 h-4 text-yellow-400" />
      ))}
      
      {/* Partial star */}
      {partialStar > 0 && (
        <div className="relative w-4 h-4">
          <div className="absolute inset-0">
            <StarOutline className="w-4 h-4 text-yellow-400" />
          </div>
          <div 
            className="absolute inset-0 overflow-hidden" 
            style={{ width: `${partialStar * 100}%` }}
          >
            <StarFilled className="w-4 h-4 text-yellow-400" />
          </div>
        </div>
      )}

      {/* Empty stars */}
      {Array.from({ length: emptyStars }).map((_, index) => (
        <StarOutline key={`empty-${index}`} className="w-4 h-4 text-yellow-400" />
      ))}
    </div>
  );
};

// Usage in ProposalsSection:
<div className="flex items-center gap-2 mt-2">
  
</div>

type SortOption = {
  label: string;
  value: "location" | "publishTime" | "price" | "stars";
};

interface SortDropdownProps {
  onSort: (value: SortOption["value"]) => void;
}

const SortDropdown = ({ onSort }: SortDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const sortOptions: SortOption[] = [
    { label: "Ubicación", value: "location" },
    { label: "Fecha de publicación", value: "publishTime" },
    { label: "Precio", value: "price" },
    { label: "Calificación", value: "stars" },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
      >
        <ArrowsUpDownIcon className="w-5 h-5" />
        Ordenar
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {sortOptions.map((option) => (
              <button
                key={option.value}
                onClick={() => {
                  onSort(option.value);
                  setIsOpen(false);
                }}
                className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};


/* const proposals: Proposal[] = []; */
const proposals = [
  { id: '1', image:'', companyName: 'Casa Limpia', description: 'Tu hogar reluciente, nosotros nos encargamos de cada detalle.', distance:'12km', publishTime:'2 min', price:'150.000', date: "2023-10-01", rating: 4.5 },
  { id: '2', image:'', companyName: 'Cleneando', description: 'Limpieza impecable, confort garantizado. ¡Relájate, nosotros lo hacemos por ti!', distance:'0.2 km', publishTime:'5 min', price:'120.000', date: "2023-10-01", rating: 1 },
  { id: '3', image:'', companyName: 'Super Clean', description: 'Transformamos tu espacio en un lugar más limpio y saludable.', distance:'2.8 km', publishTime:'1 hora', price:'220.000', date: "2023-10-01", rating: 5 },
  { id: '4', image:'', companyName: 'Cleanor', description: 'Hogar limpio, vida tranquila. Confía en los expertos en limpieza.', distance:'0.8 km', publishTime:'5 h', price:'205.000', date: "2023-10-01", rating: 2 },
];


const ProposalsSection: React.FC = () => {
  const { t } = useTranslation();
  const { response, getAllByUser } = useServiceStore();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filteredProposals, setFilteredProposals] = useState(proposals);
  const [selectedSort, setSelectedSort] =
  useState<SortOption["value"]>("publishTime");
  
  const handleSort = (value: SortOption["value"]) => {
    setSelectedFilter(value);
    
    const sortedProposals = [...proposals].sort((a, b) => {
      switch (value) {
        case "location":
          return a.distance.localeCompare(b.distance);
        case "publishTime":
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        case "price":
          return parseFloat(a.price) - parseFloat(b.price);
        case "stars":
          return b.rating - a.rating;
        default:
          return 0;
        }
    });
    setFilteredProposals(sortedProposals);
  };

  useEffect(() => {
    getAllByUser();
  }, []);
  
  console.log(response);
  return (
    <section className="px-8 pb-8">
      <h1 className="font-medium text-lg text-center">Mis Propuestas</h1>
      <div className="flex justify-between items-center mb-6">
        <SortDropdown onSort={handleSort} />
        <span className="font-semibold">
          {t("requestsAndProposals.proposals.title")} {proposals.length}
        </span>
      </div>

      {filteredProposals.length === 0 ? (
        <div className="text-center">
          <img
            src="assets/icons/brand/no-requests.png"
            alt="No proposals"
            className="mx-auto mb-4 w-48"
          />
          <h3 className="text-xl font-semibold mb-2">
            {t("requestsAndProposals.proposals.noProposals.title")}
          </h3>
          <p className="text-gray-600">
            {t("requestsAndProposals.proposals.noProposals.description")}
          </p>
        </div>
      ) : (
        <div className="grid gap-4">
          {filteredProposals.map((proposal) => (
            <div key={proposal.id} className="p-4 rounded-lg shadow-md">
              <div className="flex flex-col gap-2 justify-between">
                <div className="flex flex-row justify-center items-center gap-4">
                  <img src={proposal.image} alt="imageProposals" />
                  <div>
                    <div className="flex flex-row justify-between">
                      <h2 className="font-medium">{proposal.companyName}</h2>
                      <div className="flex items-center gap-2 mt-2 flex-row">
                      <StarRating rating={proposal.rating} />
                      <span className="text-sm text-gray-600">({proposal.rating})</span>
                      </div>
                    </div>
                    <p>{proposal.description}</p>
                  </div>
                </div>
                  <div className="flex flex-row justify-between">
                    <p className="text-lg font-semibold">{proposal.distance}</p>
                    <p className="text-gray-600">{proposal.date}</p>
                    <span className="text-lg font-semibold">
                      ${proposal.price}
                    </span>
                  </div>
                <div>
                  
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default ProposalsSection;
