import { useThemeStore } from '../../store/useThemeStore';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';


export const ThemeToggle = () => {
  const { isDarkMode, toggleDarkMode } = useThemeStore();

  return (
    <button
      onClick={toggleDarkMode}
      className="inline-flex items-center justify-center size-10 text-cText rounded-full hover:bg-cBackgroundSecondary focus:bg-cBackgroundSecondary dark:text-dark-cText dark:hover:bg-dark-cBackgroundSecondary dark:focus:bg-dark-cBackgroundSecondary"
    >
      {isDarkMode ? <SunIcon className="size-6" /> : <MoonIcon className="size-6" />}
    </button>
  );
};

export default ThemeToggle;