import { useState } from 'react';
import { LanguageIcon } from '@heroicons/react/24/outline';
import { useTranslation } from "react-i18next";


export const LanguageToggle = () => {
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguageMenuOpen(false);
  };

  const toggleLanguageMenu = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  return (
    <div className="relative inline-block text-left">
      <button 
        onClick={toggleLanguageMenu}
        className="inline-flex items-center justify-center size-10 text-cText rounded-full hover:bg-cBackgroundSecondary focus:bg-cBackgroundSecondary dark:text-dark-cText dark:hover:bg-dark-cBackgroundSecondary dark:focus:bg-dark-cBackgroundSecondary"
      >
        <LanguageIcon className="size-6" />
      </button>

      {languageMenuOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg z-20">
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
            <li>
              <button
                onClick={() => changeLanguage("es")}
                className="flex items-center w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src="assets/icons/es-flag.png"
                  alt="Español"
                  className="w-5 h-5 rounded-full mr-2"
                />
                Español
              </button>
            </li>
            <li>
              <button
                onClick={() => changeLanguage("en")}
                className="flex items-center w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src="assets/icons/en-flag.png"
                  alt="English"
                  className="w-5 h-5 rounded-full mr-2"
                />
                English
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};