import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import { EnvelopeIcon } from '@heroicons/react/24/solid';

interface Props {
  onClick?: () => void;
  isSubmitting?: boolean;
  type?: 'button' | 'submit';
  children?: React.ReactNode;
}

const EmailAuthButton: React.FC<Props> = ({
  onClick,
  isSubmitting = false,
  type = 'button',
  children
}) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      type={type}
      disabled={isSubmitting}
      className={`w-full py-3 h-12 rounded-xl font-bold transition-colors
        ${isSubmitting ? 'bg-cBackgroundSecondary' : 'bg-bgBrand hover:bg-bgBrandHover'}
        text-bgBrandLight flex items-center justify-center gap-3`}
    >
      <EnvelopeIcon className="size-6 "/>
      {children || (isSubmitting ? t('loading') : t('createAccountPage.continueWithEmail'))}
    </Button>
  );
};

export default EmailAuthButton;