import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuthStore from "../../store/useAuthStore";
import routesConfig from "../../config/routes.config";
import { FormField } from "../../components/atoms/FormField";
import { Formik, Form } from "formik";
import { getSignUpFormSchema } from "../../schemas/signUpFormSchema";
import { SignUpFormValues } from "@/types/auth.types";
import Button from "../../components/atoms/Button";
import { ThemeToggle } from "../../components/atoms/ThemeToggle";
import { LanguageToggle } from "../../components/atoms/LanguageToggle";

const SignUpForm: React.FC<{ userType: "USER" | "PROFESSIONAL" }> = ({
  userType,
}) => {
  const { register, error } = useAuthStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const PASSWORD_MIN_LENGTH = 6;
  const PASSWORD_NUMBER_REGEX = /[0-9]/;
  const PASSWORD_SYMBOL_REGEX = /[!@#$%^&*(),.?":{}|<>]/;

  const initialValues: SignUpFormValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const signUpFormSchema = getSignUpFormSchema(t, userType);

  const handleSubmit = async (values: SignUpFormValues) => {
    try {
      await register(
        values?.firstName,
        values?.lastName,
        values.email,
        values.password,
        values?.phoneNumber,
        userType
      );
      const reditectTo = routesConfig.validateEmail.replace(
        ":sendCodeTo",
        values.email
      );
      navigate(reditectTo);
    } catch (error: any) {
      console.error("Sign up failed:", error);
    }
  };

  return (
    <div className="flex min-h-screen dark:bg-dark-cBackground">
      <div className="absolute top-4 right-4 flex items-center gap-2 z-10">
        <ThemeToggle />
        <LanguageToggle />
      </div>
      <div
        className="hidden md:flex md:w-2/3 bg-cover bg-center relative"
        style={{
          backgroundImage: "url(/assets/img/backgrounds/login-side-img.jpeg)",
        }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-start top-16">
          <h1 className="text-6xl font-bold mb-4 text-bgBrand">XERVIX</h1>
          <p className="text-2xl font-light text-dark-cText">
            {t("loginPage.services")}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center md:w-1/3 w-full p-8">
        <Formik
          initialValues={initialValues}
          validationSchema={signUpFormSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ touched, isValid, dirty, isSubmitting, values }) => (
            <Form className="p-6 w-full">
              <main className="flex flex-col items-center">
                <h1 className="text-2xl font-bold text-cText dark:text-dark-cText text-center mb-4">
                  {t("signupPage.title")}
                </h1>
                <div className="w-full">
                {!userType?.includes('PROFESSIONAL') && (
                  <>
                    <FormField
                      label={t("signupPage.firstNameLabel")}
                      name="firstName"
                      type="text"
                      placeholder={t("signupPage.firstNamePlaceholder")}
                      className="w-full mb-4"
                    />
                    <FormField
                      label={t("signupPage.lastNameLabel")}
                      name="lastName"
                      type="text"
                      placeholder={t("signupPage.lastNamePlaceholder")}
                      className="w-full mb-4"
                    />
                    <FormField
                      label={t("signupPage.phoneNumberLabel")}
                      name="phoneNumber"
                      type="tel"
                      placeholder={t("signupPage.phoneNumberPlaceholder")}
                      className="w-full mb-4"
                    />
                  </>
                  )}
                  <FormField
                    label={t("signupPage.emailLabel")}
                    name="email"
                    type="email"
                    placeholder={t("signupPage.emailPlaceholder")}
                    className="w-full mb-4"
                  />
                  <FormField
                    label={t("signupPage.passwordLabel")}
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    placeholder={t("signupPage.passwordPlaceholder")}
                    toggleVisibility={() =>
                      setPasswordVisible(!passwordVisible)
                    }
                    isPasswordVisible={passwordVisible}
                    className="w-full mb-4"
                  />
                  <FormField
                    label={t("signupPage.confirmPasswordLabel")}
                    name="confirmPassword"
                    type={confirmPasswordVisible ? "text" : "password"}
                    placeholder={t("signupPage.confirmPasswordPlaceholder")}
                    toggleVisibility={() =>
                      setConfirmPasswordVisible(!confirmPasswordVisible)
                    }
                    isPasswordVisible={confirmPasswordVisible}
                    className="w-full mb-4"
                  />
                </div>
                <PasswordCriteria
                  isPasswordLengthValid={
                    values.password.length >= PASSWORD_MIN_LENGTH
                  }
                  hasNumber={PASSWORD_NUMBER_REGEX.test(values.password)}
                  hasSymbol={PASSWORD_SYMBOL_REGEX.test(values.password)}
                  passwordsMatch={
                    !!values.password &&
                    !!values.confirmPassword &&
                    values.password === values.confirmPassword
                  }
                  hasTouchedPassword={!!touched.password}
                  className="w-full mt-2"
                />
                <Button
                  type="submit"
                  variant="filled"
                  size="large"
                  className="w-full mt-6"
                  disabled={!(isValid && dirty)}
                >
                  {isSubmitting
                    ? t("signupPage.loadingButton")
                    : t("signupPage.submitButton")}
                </Button>
              </main>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const PasswordCriteria: React.FC<{
  isPasswordLengthValid: boolean;
  hasNumber: boolean;
  hasSymbol: boolean;
  passwordsMatch: boolean;
  hasTouchedPassword: boolean;
  className?: string;
}> = ({
  isPasswordLengthValid,
  hasNumber,
  hasSymbol,
  passwordsMatch,
  hasTouchedPassword,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <ul
      className={`text-sm text-cTextSecondary dark:text-dark-cTextSecondary mb-4 gap-2 flex flex-col text-left ${className}`}
    >
      <CriteriaItem
        text={t("signupPage.minLength")}
        isValid={isPasswordLengthValid}
        touched={hasTouchedPassword}
      />
      <CriteriaItem
        text={t("signupPage.hasNumber")}
        isValid={hasNumber}
        touched={hasTouchedPassword}
      />
      <CriteriaItem
        text={t("signupPage.hasSymbol")}
        isValid={hasSymbol}
        touched={hasTouchedPassword}
      />
      <CriteriaItem
        text={t("signupPage.passwordsMatch")}
        isValid={passwordsMatch}
        touched={hasTouchedPassword}
      />
    </ul>
  );
};

const CriteriaItem: React.FC<{
  text: string;
  isValid: boolean;
  touched: boolean;
}> = ({ text, isValid, touched }) => {
  return (
    <li className="flex items-start">
      <span className="mr-2">
        {isValid ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-5 text-bgBrand"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-5 text-cTextSecondary dark:text-dark-cTextSecondary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <circle cx="12" cy="12" r="10" strokeWidth={2} />
          </svg>
        )}
      </span>
      <span>{text}</span>
    </li>
  );
};

export default SignUpForm;
