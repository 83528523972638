import { useSidebarStore } from "../../store/useSidebarStore"
import { Icon } from "../atoms/Icon"

interface NavItemProps {
    icon: React.ReactNode
    label?: string
    href?: string
    onClick?: () => void
    active?: boolean
    [key: string]: any
  }
  
  export const NavItem = ({
    icon,
    label,
    href,
    onClick,
    active,
    ...props
}: NavItemProps) => {
  const isExpanded = useSidebarStore((state) => state.isExpanded)
  
  const Component = href ? 'a' : 'button'
  const componentProps = href ? { href } : { onClick }
    return (
        <Component
        {...componentProps}
        {...props}
        className={`
          flex items-center gap-3 p-2 rounded-lg
          transition-all duration-200
          ${active ? 'bg-primary-50 text-primary-600' : 'hover:bg-gray-50 text-gray-700'}
          ${isExpanded ? 'w-full' : 'w-12 justify-center'}
        `}
      >
        <Icon icon={icon} active={active} />
        {isExpanded && label && (
          <span className="font-medium whitespace-nowrap">{label}</span>
        )}
      </Component>
  )}