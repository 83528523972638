import * as Yup from "yup";

const FILE_SIZE = 2 * 1024 * 1024; // 2MB
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

export const getDynamicFormSchema = (t: (key: string) => string) => {
  return Yup.object({
    patientName: Yup.string().required("El nombre es requerido"),
    phone: Yup.string().required("El teléfono es requerido"),
    city: Yup.number().nullable() // Permite valores nulos (útil para selects no seleccionados)
    .typeError("La ciudad es requerida") // Mensaje en caso de que no sea un número válido
    .test("is-valid-city", "La ciudad es requerida", (value) => {
      return value !== 0 && value !== null && value !== undefined; // Valida que no sea 0 o nulo
    }),
    address: Yup.string().required("La dirección es requerida"),
    idealDate: Yup.string().required("La fecha es requerida"),
    idealTime: Yup.string().required("La hora es requerida"),
    selectedExams: Yup.array()
      .of(Yup.string())
      .min(1, "Selecciona al menos un examen"),
    medicalOrder: Yup.mixed()
      .nullable()
      .test("fileSize", "El archivo es muy grande", (value) => {
        if (!value) return true;
        return (value as File).size <= FILE_SIZE;
      })
      .test("fileFormat", "Formato no soportado", (value) => {
        if (!value) return true;
        return SUPPORTED_FORMATS.includes((value as File).type);
      }),
    description: Yup.string().required("La descripción es requerida"),
    requireHomeService: Yup.boolean(),
    requireVirtualService: Yup.boolean(),
    requireUrgentService: Yup.boolean(),
  });
};
