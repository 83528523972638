import React, { useState } from "react";
import {
  Bars3Icon,
  XMarkIcon,
  Squares2X2Icon,
  UserIcon,
  ChatBubbleLeftIcon,
  BriefcaseIcon,
  DocumentTextIcon,
  ShoppingCartIcon,
  HeartIcon,
  Cog6ToothIcon,
  CameraIcon,
  SunIcon,
  MoonIcon,
  ArrowLeftStartOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Navigate, useNavigate } from "react-router-dom";
import routesConfig from "../config/routes.config";
import { on } from "events";
import { SidebarDashboard } from "../components/organisms/sidebarDashboard";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  return (
    <aside
      className={`bg-white fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } lg:translate-x-0`}
    >
      <div className="h-full px-3 py-4 overflow-y-auto flex flex-col justify-between">
        <div>
          <div className="flex items-center justify-between mb-5">
            <span className="text-2xl font-semibold text-red-500">XERVIX</span>
            <button onClick={toggleSidebar} className="lg:hidden">
              <XMarkIcon />
            </button>
          </div>
          <ul className="space-y-2 font-medium">
            {[
              { icon: <Squares2X2Icon />, text: "Dashboard" },
              { icon: <UserIcon />, text: "User" },
              { icon: <ChatBubbleLeftIcon />, text: "Messages" },
              { icon: <BriefcaseIcon />, text: "Servicios" },
              { icon: <DocumentTextIcon />, text: "Mis propuestas" },
              { icon: <ShoppingCartIcon />, text: "MarketPlace" },
              { icon: <HeartIcon />, text: "Historial de pagos" },
              { icon: <Cog6ToothIcon />, text: "Configuraciones" },
              {
                icon: <ArrowLeftStartOnRectangleIcon />,
                text: "Salir",
                onClick: () => {
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("nextStep");
                  localStorage.removeItem("authResponse");
                  navigate(routesConfig.login);
                },
              },
            ].map((item, index) => (
              <li key={index} onClick={item?.onClick}>
                <a
                  href="#"
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100"
                >
                  {item.icon}
                  <span className="ml-3">{item.text} </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center p-2 mt-auto bg-gray-100 rounded-lg">
          <img
            src="/placeholder.svg?height=40&width=40"
            alt="User"
            className="w-10 h-10 rounded-full mr-3"
          />
          <div>
            <p className="text-sm font-medium text-black">Maria Fernanda Ruiz</p>
            <p className="text-xs text-gray-500">Belleza</p>
          </div>
        </div>
      </div>
    </aside>
  );
};

interface HeaderProps {
  toggleSidebar: () => void;
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const Header: React.FC<HeaderProps> = ({
  toggleSidebar,
  isDarkMode,
  toggleDarkMode,
}) => {
  return (
    <header className="pl-4 pr-8 py-2.5 flex justify-between bg-gray-100 items-center">
      <div className="flex items-center bg-green-500 text-white px-4 py-2 rounded-md">
        CUENTA ACTIVA
      </div>
      <div className="space-x-2">
          <button className="px-4 py-2">
            Navegar como Usuario
          </button>
          <button className="px-4 py-2 text-red-500">
            Navegar como Empresa
          </button>
      </div>
      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-2">
          <span>Maria Fernanda Uribe</span>
          <img
            src="/placeholder.svg?height=32&width=32"
            alt="Profile"
            className="w-8 h-8 rounded-full"
          />
        </div>
        {/* <button onClick={toggleDarkMode}>
          {isDarkMode ? <SunIcon /> : <MoonIcon />}
        </button> */}
      </div>
    </header>
  );
};

const UserProfile = () => {
  return (
    <div className="flex flex-col md:flex-row items-center gap-6">
      <div className="relative flex flex-col items-center justify-center text-center">
        <img
          src="https://placehold.co/400"
          alt="Maria Paulina Ruiz"
          className="size-40 rounded-full mb-6"
        />
        <p>Maria Paulina Ruiz</p>
        <p>Mo.gomez.20254@gmail.com</p>
        <p>The Beatue</p>
      </div>
      <div className="text-center md:text-left flex-grow">
        <div className="flex flex-row items-center">
          <div>
            <h2 className="text-3xl font-bold text-cText">Hola! Maria</h2>
            <p className="text-cTextSecondary">Tus registros generales son:</p>
          </div>
          <div>
            <img src="/assets/img/dashboard/hand" alt="Hands" className="size-20"/>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          {[
            { label: "Cotizaciones", value: "195" },
            { label: "Seguidores", value: "1209" },
            { label: "Servicios", value: "84" },
            { label: "Likes", value: "2542" },
            { label: "Ventas", value: "12'540.000" },
            { label: "Reels", value: "45" },
          ].map((item, index) => (
            <div key={index} className="border border-cBorder p-2 rounded-full w-3/4 flex flex-row gap-2 text-center items-center">
              <CheckCircleIcon className="size-6 text-textBrand" />
              <p className="text-sm text-gray-600">{item.value} {item.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ActiveServices = () => {
  return (
    <div className="w-full">
      <h3 className="text-xl text-center font-semibold mb-4">Servicios activos</h3>
      <div className="space-y-4">
        {[1, 2, 3].map((_, index) => (
          <div
            key={index}
            className="flex items-center justify-between border border-cBorder px-4 py-2 rounded-2xl"
          >
            <div className="flex items-center space-x-4">
              <img
                src="https://placehold.co/400"
                alt="Service provider"
                className="w-12 h-12 rounded-full"
              />
              <div>
                <p className="font-semibold">Maria Fernanda Patiño</p>
                <p className="text-sm text-gray-600">Manicure básico</p>
                <p className="text-xs text-gray-500">1 día</p>
              </div>
            </div>
            <span className="flex flex-row gap-2 items-center bg-[#E2F0EC] text-[#83B2A3] text-xs font-medium p-2 rounded-xl border border-cBorder">
              <CheckCircleIcon className = "size-6 text-[#83B2A3]" />
              Atendiendo
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const UpgradeCard = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
      <div>
        <h3 className="text-xl font-bold mb-4">
          🚀 ¡Lleva tu experiencia al siguiente nivel! 🚀
        </h3>
        <div className="flex flex-row gap-4 p-4">
          <p className="mb-2 text-cTextSecondary font-medium">
            ¿Quieres aprovechar funciones avanzadas que te harán la vida más
            fácil? <br /><br />
            ¡Inscríbete ahora en [Nombre de tu startup] y accede a herramientas
            exclusivas diseñadas para optimizar tu tiempo y potenciar tu negocio.
            ¡Es simple, rápido y lleno de ventajas!
          </p>
          <div>
            <img
              src="/assets/img/dashboard/person-stats.png"
              alt="Upgrade illustration"
              className="w-72 h-auto"
            />
            <button className="bg-bgBrand hover:bg-bgBrandHover text-white px-4 py-2 rounded-md">
              VER PLANES
            </button>
          </div>
        </div>
        
        
      </div>
    </div>
  );
};

const CustomerSatisfaction = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Satisfacción del cliente</h3>
      <img
        src="/placeholder.svg?height=200&width=400"
        alt="Customer Satisfaction Chart"
        className="w-full h-40 object-cover rounded-md"
      />
      <div className="flex justify-between mt-4">
        <div>
          <p className="text-sm text-gray-600">Mes pasado</p>
          <p className="font-semibold">4.8</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Este mes</p>
          <p className="font-semibold">4.9</p>
        </div>
      </div>
    </div>
  );
};

const TopServices = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Top de servicios</h3>
      <table className="w-full">
        <thead>
          <tr className="text-left">
            <th className="py-2">#</th>
            <th className="py-2">Nombre</th>
            <th className="py-2">Popularidad</th>
            <th className="py-2">Ventas</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3].map((num) => (
            <tr key={num} className="border-t">
              <td className="py-2">{num}</td>
              <td className="py-2">Disney</td>
              <td className="py-2">
                <div className="bg-red-200 h-2 w-20 rounded-full"></div>
              </td>
              <td className="py-2">
                <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                  2
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default function Dashboard() {
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("nextStep");
    localStorage.removeItem("authResponse");
    navigate(routesConfig.login);
  };
  return (
    <div className={`flex h-screen ${isDarkMode ? "dark" : ""}`}>
      <SidebarDashboard />
      <div className="flex-1 overflow-auto">
        <Header
          toggleSidebar={toggleSidebar}
          isDarkMode={isDarkMode}
          toggleDarkMode={toggleDarkMode}
        />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-4">
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="grid grid-cols-1 lg:grid-cols-[1fr,30rem] gap-6">
              <div className="w-full">
                <UserProfile />
              </div>
              <div >
                <ActiveServices />
              </div>
              </div>
            </div>

            {/* Remaining Cards */}
            <div className="grid grid-cols-1 lg:grid-cols-[1fr,25rem,25rem] gap-4">
              <UpgradeCard />
              <CustomerSatisfaction />
              <TopServices />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
