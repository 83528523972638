interface ConfirmationModalProps {
    title: string;
    message: string;
    confirmButtonText: string;
    cancelButtonText: string;
    confirmButtonColor: string;
    cancelButtonColor: string;
    onConfirm: () => void;
    onCancel: () => void;
  }
  
  export const ConfirmationModal = ({
    title,
    message,
    confirmButtonText,
    cancelButtonText,
    confirmButtonColor,
    cancelButtonColor,
    onConfirm,
    onCancel
  }: ConfirmationModalProps) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <div className="flex flex-col items-center gap-4">
          <div className="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center">
            <svg className="w-6 h-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <h3 className="text-lg font-semibold text-center">{title}</h3>
          <p className="text-center text-gray-700">{message}</p>
          <div className="flex gap-4 w-full">
            <button
              onClick={onCancel}
              className={`flex-1 px-4 py-2 text-gray-800 rounded-lg ${cancelButtonColor}`}
            >
              {cancelButtonText}
            </button>
            <button
              onClick={onConfirm}
              className={`flex-1 px-4 py-2 text-white rounded-lg ${confirmButtonColor}`}
            >
              {confirmButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  interface ServiceCompletionModalProps {
    title: string;
    message: string;
    completeButtonText: string;
    incompleteButtonText: string;
    completeButtonColor: string;
    incompleteButtonColor: string;
    companyName: string;
    onComplete: () => void;
    onIncomplete: () => void;
  }
  
  export const ServiceCompletionModal = ({
    title,
    message,
    completeButtonText,
    incompleteButtonText,
    completeButtonColor,
    incompleteButtonColor,
    companyName,
    onComplete,
    onIncomplete
  }: ServiceCompletionModalProps) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <div className="flex flex-col items-center gap-4">
          <div className="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
            <svg className="w-8 h-8 text-green-500" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-1-7s2 2 2 2l4-4" />
            </svg>
          </div>
          <h3 className="text-lg font-semibold text-center">{title}</h3>
          <p className="text-center font-medium">{companyName} acaba de finalizar el servicio prestado.</p>
          <p className="text-center text-gray-600">{message}</p>
          <div className="flex flex-col gap-2 w-full">
            <button
              onClick={onComplete}
              className={`w-full px-4 py-2 text-white rounded-lg ${completeButtonColor}`}
            >
              {completeButtonText}
            </button>
            <button
              onClick={onIncomplete}
              className={`w-full px-4 py-2 text-gray-800 rounded-lg ${incompleteButtonColor}`}
            >
              {incompleteButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
  interface InformationModalProps {
    title: string;
    message: string;
    terms: boolean;
    acceptButtonText: string;
    acceptButtonColor: string;
    onAccept: () => void;
  }
  
  export const InformationModal = ({
    title,
    message,
    terms,
    acceptButtonText,
    acceptButtonColor,
    onAccept
  }: InformationModalProps) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <div className="flex flex-col items-center gap-4">
          <div className={`w-12 h-12 rounded-full ${acceptButtonColor}-100 flex items-center justify-center`}>
            <svg className={`w-8 h-8 ${acceptButtonColor} rounded-2xl`} viewBox="0 0 24 24" fill="currentColor">
            </svg>
          </div>
          <h3 className="text-lg font-semibold text-center">{title}</h3>
          <p className="text-center text-gray-700">{message}</p>
          {terms && 
            ( 
                <button
                    onClick={onAccept}
                    className="w-full text-red-500 underline"
                >
                    Ver políticas de cancelación
                </button>
            ) 
          }
          <button
            onClick={onAccept}
            className={`w-6/12 px-4 py-2 text-white rounded-lg ${acceptButtonColor}`}
          >
            {acceptButtonText}
          </button>
        </div>
      </div>
    </div>
  );