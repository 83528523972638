import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input'; // Importar el componente de teléfono
import 'react-phone-number-input/style.css'; // Estilo para PhoneInput
import useOnboardingStore from '../../store/useOnboardingStore'; // Importamos el store
import { ArrowUpTrayIcon, DocumentTextIcon, PencilIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/24/solid';
import Modal from '@/components/Modals/Modal';



// Constants for document types
const DOCUMENT_TYPES = {
  LOGO: 0,
  CEDULA_REPRESENTANTE: 1,
  CAMARA_COMERCIO: 2,
  CERTIFICADO_BANCARIO: 3,
  RUT: 4,
} as const;

// Interface for document mapping
interface DocumentMapping {
  key: string;
  type: number;
  label: string;
}

const BUSINESS_TYPES = {
  NATURAL: '30f08549-8bc1-4a2f-9c74-a8b648d63d05' /* 'Persona Natural' */,
  COMPANY: '8a0cac57-b85b-4c80-8bdb-a8174267e079' /* 'Empresa Legalmente Constituida' */
} as const;

const getDocumentConfig = (businessType: string): DocumentMapping[] => {
  if (businessType === BUSINESS_TYPES.NATURAL) {
    return [
      { key: 'cedula', type: DOCUMENT_TYPES.CEDULA_REPRESENTANTE, label: 'Cédula' },
      { key: 'certificadoBancario', type: DOCUMENT_TYPES.CERTIFICADO_BANCARIO, label: 'Certificado Bancario' },
      { key: 'rut', type: DOCUMENT_TYPES.RUT, label: 'RUT' }
    ];
  }
  return [
    { key: 'cedulaRepresentante', type: DOCUMENT_TYPES.CEDULA_REPRESENTANTE, label: 'Cédula Representante' },
    { key: 'camaraComercio', type: DOCUMENT_TYPES.CAMARA_COMERCIO, label: 'Cámara de Comercio' },
    { key: 'certificadoBancario', type: DOCUMENT_TYPES.CERTIFICADO_BANCARIO, label: 'Certificado Bancario' },
    { key: 'rut', type: DOCUMENT_TYPES.RUT, label: 'RUT' }
  ];
};

// Add these interfaces after the existing interfaces
interface NaturalPersonForm {
  firstName: string;
  lastName: string;
  identification: string;
  phoneNumber: string;
  description: string;
}

interface CompanyForm {
  companyName: string;
  nit: string;
  phoneNumber: string;
  description: string;
}

type BusinessFormData = NaturalPersonForm | CompanyForm;

const BusinessOnboardingPage: React.FC<{ onNext: () => void }> = ({ onNext }) => {
  const { t } = useTranslation();
  const {
    businessTypes,
    fetchBusinessTypes,
    upsertBusinessInfo,
    uploadDocuments,
    isLoading,
    nextStep,
  } = useOnboardingStore();

  const [logo, setLogo] = useState<File | null>(null);
  const [businessType, setBusinessType] = useState('');
  const [formData, setFormData] = useState<BusinessFormData>({
    firstName: '',
    lastName: '',
    identification: '',
    phoneNumber: '',
    description: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [documents, setDocuments] = useState<{ [key: string]: File | null }>({
    cedulaRepresentante: null,
    camaraComercio: null,
    certificadoBancario: null,
    rut: null,
  });
  const [businessDescription, setBusinessDescription] = useState<string>('');
  const [uploading, setUploading] = useState<{ [key: string]: boolean }>({
    cedulaRepresentante: false,
    camaraComercio: false,
    certificadoBancario: false,
    rut: false,
  });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [showDocsModal, setShowDocsModal] = useState(false);
  const [missingDocs, setMissingDocs] = useState<string[]>([]);
  const [uploadedDocs, setUploadedDocs] = useState<string[]>([]);

  const navigate = useNavigate();

  // Fetch business types when the component is mounted
  useEffect(() => {
    fetchBusinessTypes();
  }, [fetchBusinessTypes]);

  // Add helper function
  const checkDocuments = (businessType: string): boolean => {
    if (!businessType) return false;

    const requiredDocs = getDocumentConfig(businessType);
    const missing: string[] = [];
    const uploaded: string[] = [];

    requiredDocs.forEach(({ key, label }) => {
      if (!documents[key]) {
        missing.push(label);
      } else {
        uploaded.push(label);
      }
    });

    setMissingDocs(missing);
    setUploadedDocs(uploaded);
    return missing.length === 0;
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setError(t('onboarding.business.invalidImageType'));
      return;
    }
  
    // Validate file size (e.g., 5MB limit)
    const MAX_SIZE = 5 * 1024 * 1024; // 5MB
    if (file.size > MAX_SIZE) {
      setError(t('onboarding.business.fileTooLarge'));
      return;
    }
  
    setLogo(file);
    setError(null);
  };

  const handleDocumentChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;
  
    try {
      setUploading((prev) => ({ ...prev, [key]: true }));
      
      // Update state with new file
      setDocuments((prev) => ({ ...prev, [key]: file }));
      
    } catch (error) {
      setError(t('onboarding.business.errorUploadingDocument'));
      console.error('Error uploading document:', error);
    } finally {
      setUploading((prev) => ({ ...prev, [key]: false }));
    }
  };

  const isValidFileType = (file: File, types: string[]) => {
    return types.some(type => file.type.startsWith(type));
  };
  
  const validateDocument = (file: File, key: string): boolean => {
    const MAX_SIZE = 10 * 1024 * 1024; // 10MB
  
    if (file.size > MAX_SIZE) {
      setError(t('documents.fileTooLarge', { size: '10' }));
      return false;
    }
  
    // Validate file types based on document type
    const validTypes = key === 'logo' 
      ? ['image/']
      : ['application/pdf', 'image/'];
  
    if (!isValidFileType(file, validTypes)) {
      setError(t('documents.invalidFileType'));
      return false;
    }
  
    return true;
  };

  useEffect(() => {
    fetchBusinessTypes();
    
    // Cleanup function
    return () => {
      // Revoke any object URLs created
      if (logo) URL.revokeObjectURL(URL.createObjectURL(logo));
      Object.values(documents).forEach(doc => {
        if (doc) URL.revokeObjectURL(URL.createObjectURL(doc));
      });
    };
  }, [fetchBusinessTypes]);
  
  const getFileIcon = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') return <DocumentTextIcon className="w-6 h-6 text-red-500" />;
    if (['xls', 'xlsx'].includes(extension || ''))
      return <DocumentTextIcon className="w-6 h-6 text-green-500" />;
    return <DocumentTextIcon className="w-6 h-6 text-gray-500" />;
  };

  // Manejo del formulario cuando el usuario lo envía
  const handleSubmit = async () => {
    setError(null);

    const allDocsUploaded = checkDocuments(businessType);
    if (!allDocsUploaded) {
      setShowDocsModal(true);
      return;
    }

    try {
      // Primero actualizar la información del negocio
      await upsertBusinessInfo({
        businessType,
        name: businessType === BUSINESS_TYPES.COMPANY ? (formData as CompanyForm).companyName : (formData as NaturalPersonForm).firstName + ' ' + (formData as NaturalPersonForm).lastName,
        identification: businessType === BUSINESS_TYPES.COMPANY ? (formData as CompanyForm).nit : (formData as NaturalPersonForm).identification,
        phoneNumber: formData.phoneNumber,
        businessDescription
      });

      // Preparar los documentos para subir
      const documentFiles: File[] = [];
      const documentTypes: number[] = [];

      // Si hay logo, agregarlo primero
      if (logo) {
        documentFiles.push(logo);
        documentTypes.push(DOCUMENT_TYPES.LOGO);
      }

      // Agregar los demás documentos
      getDocumentConfig(businessType).forEach(({ key, type }) => {
        const doc = documents[key];
        if (doc) {
          documentFiles.push(doc);
          documentTypes.push(type);
        }
      });

      // Subir documentos si hay alguno
      if (documentFiles.length > 0) {
        await uploadDocuments(undefined, documentFiles, documentTypes);
      }

      onNext();
    } catch (err) {
      setError(t('onboarding.business.errorOnSubmit'));
      console.error('Error submitting form:', err);
    }
  };

  const proceedWithoutDocs = async () => {
    try {
      await upsertBusinessInfo({
        businessType,
        name: businessType === BUSINESS_TYPES.COMPANY ? 
          (formData as CompanyForm).companyName : 
          (formData as NaturalPersonForm).firstName + ' ' + (formData as NaturalPersonForm).lastName,
        identification: businessType === BUSINESS_TYPES.COMPANY ? 
          (formData as CompanyForm).nit : 
          (formData as NaturalPersonForm).identification,
        phoneNumber: formData.phoneNumber,
        businessDescription
      });

      // Upload logo if exists
      if (logo) {
        await uploadDocuments(undefined, [logo], [DOCUMENT_TYPES.LOGO]);
      }

      // Upload any documents that were provided
      const availableDocuments = Object.entries(documents)
        .filter(([_, file]) => file !== null)
        .map(([key, file]) => ({
          file,
          type: getDocumentConfig(businessType).find(doc => doc.key === key)?.type
        }))
        .filter(item => item.file && item.type);

      if (availableDocuments.length > 0) {
        await uploadDocuments(
          undefined,
          availableDocuments.map(d => d.file!) as File[],
          availableDocuments.map(d => d.type!) as number[]
        );
      }

      setShowDocsModal(false);
      onNext();
    } catch (err) {
      setError(t('onboarding.business.errorOnSubmit'));
      console.error('Error submitting form:', err);
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  // Mostrar un estado de carga si aún no se ha obtenido la lista de tipos de negocio
  if (isLoading) {
    return <div className="text-center">{t('loading')}</div>;
  }

  return (
    <>
    <div className="p-8 bg-white flex items-center justify-center">
      <div className="w-full mx-auto text-center">
        {/* <h1 className="text-3xl font-bold mb-6">
          {t('onboarding.business.title')}
        </h1> */}

        {/* Muestra el error si existe */}
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Columna izquierda */}
          <div>
          <div className="mb-4">
              <div className="size-48 bg-gray-200 rounded-full flex items-center content-center justify-center overflow-hidden border relative">
                <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 relative">
                {logo ? (
                  <img src={URL.createObjectURL(logo)} alt="Logo" className="w-full h-full object-cover" />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <ArrowUpTrayIcon className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                    </div>
                )}
                <input id="dropzone-file" type="file" accept="image/*" onChange={handleLogoChange} ref={fileInputRef} className="hidden" />
                  {logo && (
                    <PencilIcon
                    className="w-6 h-6 text-black bg-primary-600 rounded-full p-1 absolute bottom-2 right-2 cursor-pointer"
                    onClick={() => fileInputRef.current?.click()}
                    />
                  )}
                </label>
                </div>
                  <h2 className="block text-gray-700 mb-2">{t('onboarding.business.logo')}</h2>
            </div>
              {/* Tipo de sociedad */}
              <div className="mb-6">
                <label htmlFor="businessType" className="block text-left mb-2">
                  {t('onboarding.business.businessType')}
                </label>
                <select
                  id="businessType"
                  value={businessType}
                  onChange={(e) => {
                    setBusinessType(e.target.value);
                    setFormData(
                      e.target.value === BUSINESS_TYPES.NATURAL
                        ? {
                            firstName: '',
                            lastName: '',
                            identification: '',
                            phoneNumber: '',
                            description: '',
                          }
                        : {
                            companyName: '',
                            nit: '',
                            phoneNumber: '',
                            description: '',
                          }
                    );
                  }}
                  className="w-full px-4 py-2 border rounded-lg"
                >
                  <option value="">{t('onboarding.business.selectBusinessType')}</option>
                  {businessTypes.map((type) => (
                    <option key={type.businessTypeId} value={type.businessTypeId}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {businessType === BUSINESS_TYPES.NATURAL ? (
                <>
                  <div className="mb-6">
                    <label htmlFor="firstName" className="block text-left mb-2">Nombres</label>
                    <input
                      type="text"
                      id="firstName"
                      value={(formData as NaturalPersonForm).firstName}
                      onChange={(e) => handleInputChange('firstName', e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg"
                    />
                  </div>
                  <div className="mb-6">
                    <label htmlFor="lastName" className="block text-left mb-2">Apellidos</label>
                    <input
                      type="text"
                      id="lastName"
                      value={(formData as NaturalPersonForm).lastName}
                      onChange={(e) => handleInputChange('lastName', e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg"
                    />
                  </div>
                </>
              ) : businessType === BUSINESS_TYPES.COMPANY ? (
                <div className="mb-6">
                  <label htmlFor="companyName" className="block text-left mb-2">Nombre de la empresa</label>
                  <input
                    type="text"
                    id="companyName"
                    value={(formData as CompanyForm).companyName}
                    onChange={(e) => handleInputChange('companyName', e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>
              ) : null}

              <div className="mb-6">
                <label htmlFor="identification" className="block text-left mb-2">
                  {businessType === BUSINESS_TYPES.COMPANY ? 'NIT' : 'Número de identificación'}
                </label>
                <input
                  type="text"
                  id="identification"
                  value={businessType === BUSINESS_TYPES.COMPANY ? 
                    (formData as CompanyForm).nit : 
                    (formData as NaturalPersonForm).identification}
                  onChange={(e) => handleInputChange(
                    businessType === BUSINESS_TYPES.COMPANY ? 'nit' : 'identification',
                    e.target.value
                  )}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>

              {/* Número de teléfono con indicativo */}
              <div className="mb-6">
                <label htmlFor="phoneNumber" className="block text-left mb-2">
                  {t('onboarding.business.phoneNumber')}
                </label>
                <PhoneInput
                  international
                  defaultCountry="CO" // País por defecto
                  value={formData.phoneNumber}
                  onChange={(e) => handleInputChange('phoneNumber', e as string)} // Ajusta el valor del teléfono
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
          </div>

          {/* second derecha */}
          <div>
            {/* Descripción de la empresa */}
            <div className="mb-6 h-full">
              <label htmlFor="description" className="block text-left mb-2">
                {businessType === BUSINESS_TYPES.NATURAL ? 'Cuéntanos un poco de ti' : 'Cuéntanos un poco de la empresa'}
              </label>
              <textarea
                id="description"
                value={formData.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                className="w-full h-full px-4 py-2 border rounded-lg"
                placeholder={t('onboarding.business.businessDescriptionPlaceholder')}
              />
            </div>
          </div>
          {/* Third Column */}
          <div>
              {businessType && getDocumentConfig(businessType).map(({ key, label }) => (
                <div key={key} className="mb-6">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor={key}>{label}</label>
                <input
                  className="block w-full mb-5 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  id={key}
                  type="file"
                  accept=".pdf,.xls,.xlsx"
                  onChange={(e) => handleDocumentChange(e, key)}
                />
                {uploading[key] && (
                  <div className="mt-2 w-full bg-gray-200 rounded-full h-2">
                    <div className="bg-blue-500 h-2 rounded-full animate-pulse"></div>
                  </div>
                )}
                {documents[key] && (
                  <div className="mt-2 flex items-center text-gray-700">
                    {documents[key]!.name}
                    <XCircleIcon
                      className="w-5 h-5 text-red-500 cursor-pointer ml-2"
                      onClick={() =>
                        setDocuments((prev) => ({ ...prev, [key]: null }))
                      }
                    />
                  </div>
                )}
              </div>
          ))}

              {/* Botón de continuar */}
              <div className="mt-8">
                <button
                  onClick={handleSubmit}
                  disabled={isLoading}
                  className={`px-8 py-3 rounded-lg ${!businessType || !formData.phoneNumber || !formData.description
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-red-500 text-white'
                    }`}
                >
                  {t('onboarding.business.continue')}
                </button>
              </div>
        </div>

          </div>
      </div>
    </div>
    <Modal
        isOpen={showDocsModal}
        onClose={() => setShowDocsModal(false)}
        title="Documentos Pendientes"
      >
        <div className="p-4 max-w-lg">
          <p className="mb-4 leading-relaxed">
            Puedes continuar con el registro, pero ten en cuenta que los siguientes documentos son necesarios para completar tu perfil:
          </p>
          
          {uploadedDocs.length > 0 && (
            <>
              <p className="font-semibold text-green-600 mt-4">Documentos subidos:</p>
              <ul className="list-disc pl-5 mt-2">
                {uploadedDocs.map(doc => (
                  <li key={doc} className="text-green-600">{doc}</li>
                ))}
              </ul>
            </>
          )}

          {missingDocs.length > 0 && (
            <>
              <p className="font-semibold text-red-600 mt-4">Documentos pendientes:</p>
              <ul className="list-disc pl-5 mt-2">
                {missingDocs.map(doc => (
                  <li key={doc} className="text-red-600">{doc}</li>
                ))}
              </ul>
            </>
          )}

          <div className="flex justify-center items-center gap-4 mt-6">
            <button
              onClick={() => setShowDocsModal(false)}
              className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            >
              Subir documentos
            </button>
            <button
              onClick={proceedWithoutDocs}
              className="px-4 py-2 bg-bgBrand text-white rounded-lg hover:bg-bgBrandHover"
            >
              Continuar con el registro
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BusinessOnboardingPage;
