import React from "react";
import { Card } from "./Card";

interface CardSkeletonProps {
  className?: string;
}

const CardSkeleton = ({
  className = "",
}: CardSkeletonProps) => {
  return (
    <div className={`flex-none w-64 cursor-pointer select-none ${className}`}>
      <Card>
        <div className="bg-cTextSecondary opacity-50 dark:opacity-100 dark:bg-dark-cTextSecondary w-full h-60 xl:h-64 object-cover rounded-3xl p-2 animate-pulse" />
        <div className="p-4 space-y-2">
        {Array.from({ length: 5 }).map((_, index) => (
            <div
            key={index}
            className={`w-full h-4 bg-cTextSecondary opacity-50 dark:opacity-100 dark:bg-dark-cTextSecondary rounded animate-pulse`}
            />
        ))}
        </div>
      </Card>
    </div>
  );
};

export default CardSkeleton;
