import React from "react";
import classNames from "classnames";

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'secondary' | 'selected' | 'outline';
  size?: 'sm' | 'md' | 'lg';
  hoverable?: boolean;
  children: React.ReactNode;
}

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, variant = 'default', size = 'md', hoverable = false, children, ...props }, ref) => {
    const baseStyles = "rounded-3xl shadow-md overflow-hidden";
    
    const variants = {
      default: "bg-white dark:bg-dark-cBackgroundSecondary",
      secondary: "bg-gray-50 dark:bg-gray-800",
      selected: "bg-bgBrand hover:bg-bgBrandHover text-textOnBrand",
      outline: "border border-gray-200 dark:border-gray-700"
    };

    const sizes = {
      sm: "p-4",
      md: "p-6",
      lg: "p-8"
    };

    const hoverStyles = hoverable ? "transition-transform duration-200 hover:scale-[1.02]" : "";

    return (
      <div
        ref={ref}
        className={classNames(
          baseStyles,
          variants[variant],
          sizes[size],
          hoverStyles,
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Card.displayName = "Card";