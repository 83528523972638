import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useOnboardingStore from '../../store/useOnboardingStore'; // Asumiendo que tienes un store similar al de Flutter
import routesConfig from '../../config/routes.config';




const SuccessOnboardingPage: React.FC = () => {
  const { t } = useTranslation(); // Para las traducciones
  const navigate = useNavigate(); // Para la navegación
  const { completeOnboarding, isLoading } = useOnboardingStore(); // Asumiendo que esto está en tu store

  const [loading, setLoading] = useState(false);
  // Obtener los datos de autenticación almacenados
  const authData = localStorage.getItem('authResponse');
  const parsedAuthData = authData ? JSON.parse(authData) : null;

  // Completar el onboarding
  const handleCompleteOnboarding = async () => {
    setLoading(true);
    try {
      await completeOnboarding(); // Llamamos al método del store
      if (parsedAuthData) {
        // Asegurarse que el token sigue siendo válido
        if (parsedAuthData.accessToken) {
          navigate(routesConfig.home);
        } else {
          // Si el token no es válido, redirigir al login
          navigate(routesConfig.login);
        }
      } else {
        navigate(routesConfig.login);
      }
    } catch (error: any) {
      alert(t('onboarding.errorMessage', { error: error.toString() })); // Mostramos error
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-white p-6">
      {/* Imagen de fondo */}
      <div className="absolute inset-0">
        <img
          src="/assets/img/success_background.png"
          alt="Success Background"
          className="w-full h-full object-cover"
        />
      </div>

      <div className="relative z-10 flex flex-col items-center">
        {/* Imagen de éxito */}
        <img
          src="/assets/icons/brand/success.png"
          alt="Success Icon"
          className="w-68 h-48"
        />
        <h1 className="text-3xl font-bold text-black mt-6 text-center">
          {t('onboarding.successTitle')}
        </h1>
        <p className="text-lg text-gray-700 mt-4 text-center">
          {t('onboarding.successDescription')}
        </p>

        {/* Botón de aceptar */}
        <div className="mt-8">
          {loading ? (
            <div className="text-red-500">
              <i className="fas fa-spinner fa-spin"></i> {t('onboarding.loading')}
            </div>
          ) : (
            <button
              onClick={handleCompleteOnboarding}
              className="bg-red-500 text-white px-12 py-3 rounded-full"
            >
              {t('onboarding.acceptButton')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessOnboardingPage;
