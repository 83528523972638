import { xervixApi, xervixApiMultipart } from '../config/axios.config'; // Importamos xervixApi para manejar automáticamente el token
import { API_ROUTES } from '../constants/apiRoutes';
import { CreateServiceRequest,UploadImagesRequest } from '../types/request.types';


export const submitServiceRequest = async (data: CreateServiceRequest): Promise<{serviceRequestId: string}> => {
  try {
    const response = await xervixApi.post(API_ROUTES.REQUEST.SUBMIT_REQUEST, data);
    return response.data;
  } catch (error: any) {
    if(error.response){
      throw error;
    }
    throw new Error(error);
  }
};

export const uploadImagesServiceRequest = async (serviceRequestId: string, dataFields: UploadImagesRequest): Promise<any> => {
  const formData = new FormData();
  dataFields.files.forEach((file) => {
    formData.append('images', file);
  });
  const response = await xervixApi.post(
    API_ROUTES.REQUEST.UPLOAD_IMAGES(serviceRequestId),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
};

export const fetchModeServiceRequest = async (): Promise<any> => {
  const response = await xervixApi.get(
    API_ROUTES.ENUMS.SERVICE_MODES
  );
  return response.data;
};

export const fetchServiceRequestGetAll = async (): Promise<any> => {
  const response = await xervixApi.get(
    API_ROUTES.REQUEST.FETCH_REQUESTS
  );
  return response.data;
}

export const fetchRequestByStatus = async (serviceRequestId:string, serviceRequestStatus: String): Promise<any> => {
  const response = await xervixApi.get(
    API_ROUTES.REQUEST.FETCH_REQUESTS,
    {
      params: {
        serviceRequestId: serviceRequestId,
        serviceRequestStatus: serviceRequestStatus,
      },
    }
  );
  return response.data;
}

export default {
  submitServiceRequest,
};


