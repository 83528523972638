import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

type ButtonVariant = 'filled' | 'outlined';
type ButtonSize = 'small' | 'medium' | 'large';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant;
    size?: ButtonSize;
    uppercase?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    type = 'button',
    variant = 'filled',
    size = 'medium',
    uppercase = false,
    className,
    children,
    disabled,
    ...rest
}) => {
    const baseClasses = "font-semibold transition-colors rounded-lg";
    
    const variantClasses = {
        filled: "bg-bgBrand hover:bg-bgBrandHover text-textOnBrand",
        outlined: "border border-bgBrand text-bgBrand hover:bg-bgBrand hover:text-textOnBrand"
    };

    const sizeClasses = {
        small: "py-2 px-4",
        medium: "py-3 px-6",
        large: "py-4 px-8"
    };

    const disabledClasses = "bg-bgDisabled text-textDisabled cursor-not-allowed hover:bg-bgDisabled dark:bg-dark-bgDisabled dark:text-dark-textDisabled";

    return (
        <button
            type={type}
            className={classNames(
                baseClasses,
                variantClasses[variant],
                sizeClasses[size],
                uppercase && "uppercase",
                disabled && disabledClasses,
                className
            )}
            disabled={disabled}
            {...rest}
        >
            {children}
        </button>
    );
}

export default Button;