import React, {useState} from "react";
import ServiceCard from "./ServiceCard";
import DetailService from "./DetailService";
import { useNavigate } from "react-router-dom";

export const ActiveServices: React.FC = () => {
    const [selectedService, setSelectedService] = useState<any | null>(null);
    const navigate = useNavigate();
  const servicios = [
    {
      id: 1,
      categoria: "Mascotas",
      descripcion: "Guardería para perro",
      fecha: "28 de mayo de 2025",
      proveedor: "Cambridge Pets",
      imagen: "https://via.placeholder.com/150",
    },
  ];

  const handleRequestServiceClick = () => {
    navigate("/categories");
  };

  const handleServiceClick = (servicio: any) => {
    setSelectedService(servicio);
  };

  if (selectedService) {
    return <DetailService servicio={selectedService} onBack={() => setSelectedService(null)} />;
  }

  return (
    <div className="flex justify-between items-center p-6 bg-gray-100 rounded-lg shadow-md">
      {servicios.length === 0 ? (
        <div className="text-center p-6 bg-white rounded-lg shadow-md">
          <h3 className="font-bold text-lg">Actualmente no tienes servicios activos</h3>
          <p className="text-gray-700 mt-2 text-sm">
            ¡En XERVIX, no hay límites para ti! Puedes activar tantos servicios como necesites...
          </p>
          <button className="mt-4 px-6 py-2 bg-red-500 text-white rounded-lg">
            Solicitar Servicio
          </button>
        </div>
      ) : (
        <div className="flex flex-1">
          <div className="grid grid-cols-1 gap-4 w-1/2">
            {servicios.map((servicio) => (
              <ServiceCard key={servicio.id} servicio={servicio} onClick={() => handleServiceClick(servicio)} />
            ))}
          </div>
          <div className="flex flex-col justify-center w-1/2 bg-white p-6 rounded-lg shadow-md">
            <h3 className="font-bold text-lg mb-2">¡En XERVIX, no hay límites para ti!</h3>
            <p className="text-gray-700 text-sm">
              Explora todas nuestras opciones y descubre cómo podemos ayudarte.
            </p>
            <button className="mt-4 px-6 py-2 bg-red-500 text-white rounded-lg" onClick={handleRequestServiceClick}>
              Solicitar Servicio
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveServices;
